import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Button,
    Text,
    Box,
  } from "@chakra-ui/react";
import { linksConfig } from "../../utils/CommonLinks";

  
  const HomepageDisclaimer = ({ setShowDisclaimer }) => {
    
    return (
      <Modal isOpen={true} size={{base: "xs", lg: "lg"}} onClose={() => setShowDisclaimer(false)}>
        <ModalOverlay />
        <ModalContent bgColor={"brand.black"} borderRadius={"xl"}>
          <ModalHeader
            textAlign={"center"}
            color={"brand.red"}
            fontFamily={"heading"}
            fontWeight={400}
            fontSize={"2xl"}
          >
            Important
          </ModalHeader>
          <ModalCloseButton color={"brand.white"}/>
          <ModalBody>
          <Box pb={4}>
            <Text textAlign={"center"} color={"brand.white"}>
              Shyft Discord has been recovered.<br/> Our Twitter handles are still compromised, please do not interact with @codinpsycho and @Shyft_to handles.<br/>
               
            </Text>
            <Text as={"div"} pt={"1px"} pb={2} textAlign={"center"} color={"brand.white"}>
              <Text as="span" color={"brand.yellow"}>Open a support ticket from the dashboard</Text> for any queries.
            </Text>
          </Box>
            {/* <Box pt={4} pb={4} textAlign={"center"}>
              <Button
                as="a"
                href={linksConfig.DISCORD_LINK}
                target="_blank"
                bgColor={"brand.red"}
                fontFamily={"heading"}
                color={"brand.white"}
                _hover={{ bg: "brand.white", color: "brand.red" }}
                borderRadius={"full"}
                fontWeight={600}
                fontSize={"sm"}
                letterSpacing={"0.01rem"}
                px={8}
              >
                Join Discord
              </Button>
            </Box> */}
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  };
  
  export default HomepageDisclaimer;  