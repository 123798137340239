import { Box, Container, Heading, UnorderedList, ListItem, Text, Link } from '@chakra-ui/react';
import { useEffect } from "react";
import ReactGA from "react-ga4";
import { linksConfig } from '../../utils/CommonLinks';



const Refund = () => {
    useEffect(() => {
        document.title = 'Refund Policy - SHYFT';
        ReactGA.send({ hitType: "pageview", page: "/refund-policy" });
    }, []);
    return ( 
        <>
            <Box bg={'brand.black'} minH={"55vh"}>
                <Container maxW={'7xl'} color={'brand.white'} py={'12'} px={8}>
                    <Heading as="h1" fontSize={{ base: '2xl', lg: '5xl' }}>
                        SHYFT - Refund Policy
                    </Heading>

                    <Box as="div" py={{ base: '24px', lg: '32px' }} textAlign={'justify'}>
                        At Shyft, we strive to provide high-quality services and ensure customer satisfaction. 
                        However, we understand that sometimes a purchase may not meet your expectations. 
                        Our refund policy is designed to make the process smooth and transparent.
                    </Box>

                    <Box py={{ base: '16px', lg: '24px' }} textAlign={'justify'}>
                        <Text fontSize={{ base: 'lg', lg: '2xl' }} pb={2}>
                            Eligibility for Refund
                        </Text>
                        We offer refunds for purchases made on Shyft within <Text as={'span'} color={"brand.yellow"}>3 days</Text> of the transaction date. 
                        Refunds will be credited back to the original payment method used at the time of purchase.
                    </Box>
                    <Box py={{ base: '16px', lg: '24px' }} textAlign={'justify'}>
                        <Text fontSize={{ base: 'lg', lg: '2xl' }} pb={2}>
                            How to Request a Refund
                        </Text>
                        To initiate a refund request, please reach out to our Dashboard Support or <Link href={linksConfig.DISCORD_LINK} color={"brand.yellow"} textDecoration={"none"} _hover={{ color: "brand.red" }}>discord</Link> and provide the following details:
                        <UnorderedList py={{ base: '12px', lg: '16px' }}>
                            <ListItem>
                                Your API key
                            </ListItem>
                            <ListItem>
                                The email associated with your purchase
                            </ListItem>
                            <ListItem>A brief explanation of the reason for your refund request</ListItem>
                        </UnorderedList>
                        You can also contact us directly via email at <Link href="mailto:support@shyft.to" color={"brand.yellow"} textDecoration={"none"} _hover={{ color: "brand.red" }}>support@shyft.to</Link> for assistance. Our support team will review your request and guide you through the next steps to ensure a seamless process.
                    </Box>
                    <Box py={{ base: '16px', lg: '24px' }} textAlign={'justify'}>
                        <Text fontSize={{ base: 'lg', lg: '2xl' }} pb={2}>
                            Refund Processing Time
                        </Text>
                        Once we receive your refund request, we will process it promptly. 
                        Please allow up to <Text as={'span'} color={"brand.yellow"}>7 days</Text> for the refund to be completed and reflected in your payment account.
                    </Box>
                    <Box py={{ base: '16px', lg: '24px' }} textAlign={'justify'}>
                        <Text fontSize={{ base: 'lg', lg: '2xl' }} pb={2}>
                            Need Assistance?
                        </Text>
                        If you have any questions regarding our refund policy or require further support, please reach out to our support via dashboard support, 
                        <Link href={linksConfig.DISCORD_LINK} color={"brand.yellow"} textDecoration={"none"} _hover={{ color: "brand.red" }}>discord</Link> or
                        , email us at <Link href="mailto:support@shyft.to" color={"brand.yellow"} textDecoration={"none"} _hover={{ color: "brand.red" }}>support@shyft.to</Link>.  Our team is available to assist you with any concerns.

                        <br /><br />Thank you for choosing Shyft!
                    </Box>
                </Container>
            </Box>
            
        </>
    );
}
 
export default Refund;
