import { Box, Text } from "@chakra-ui/react";
import { motion } from 'framer-motion';
const SupportHiglighter = () => {
const MotionBox = motion(Box);
const MotionText = motion(Text);
  return (
    <MotionBox
      as="div"
      display={"inline-block"}
      position={"fixed"}
      width={"132px"}
      height={"32px"}
      bg={"brand.red"}
      zIndex={99}
      px={4}
      py={"2px"}
      borderRadius={"full"}
      bottom={"28px"}
      right={"76px"}
      className={"pop_dance_anim"}
      textAlign={"center"}
      border={"2px solid"}
      borderColor={"brand.red"}
      
      initial={{ opacity: 0, width: "0px" }}
      animate={{ opacity: 1, width: "132px" }}
      transition={{ delay: 0, duration: 0.2, exit: { opacity: 0 } }}
    >
      <MotionText color={"brand.white"} fontWeight={600} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.2, duration: 0.1,exit: { opacity: 0 }  }}>Get Support</MotionText>
    </MotionBox>
  );
};

export default SupportHiglighter;
