import { useState, useEffect } from "react";
import {
  useParams,
  useNavigate,
  Link,
  useSearchParams,
} from "react-router-dom";
import axios from "axios";
import { useWallet } from "@solana/wallet-adapter-react";

// import { CardElement, Elements, ElementsConsumer } from '@stripe/react-stripe-js';
// import { loadStripe } from '@stripe/stripe-js';

import { ReactSession } from "react-client-session";
import SidebarCss from "./sidebar.module.css";

import overviewIcon from "../../../resources/images/sidebar/overview.svg";
import apiUsageIcon from "../../../resources/images/sidebar/api-usage.svg";
import resourcesIcon from "../../../resources/images/sidebar/resources.svg";
import pricingIcon from "../../../resources/images/sidebar/pricing.svg";
import billingIcon from "../../../resources/images/sidebar/billing.svg";
import supportIcon from "../../../resources/images/sidebar/support.svg";
//import apiCreditIcon from '../../../resources/images/sidebar/api-credits.svg';

import overviewColor from "../../../resources/images/sidebar/overview-color.svg";
import apiUsageColor from "../../../resources/images/sidebar/api-usage-color.svg";
import resourcesColor from "../../../resources/images/sidebar/resources-color.svg";
import pricingColor from "../../../resources/images/sidebar/pricing-color.svg";
import billingColor from "../../../resources/images/sidebar/billing-color.svg";
import supportColor from "../../../resources/images/sidebar/support-color.svg";
//import apiCreditColor from '../../../resources/images/sidebar/api-credits-color.svg';

import Loading from "../Loading";
import Support from "../../Dashboard/Support";
import Billings from "../../Dashboard/Billings";
import Resources from "../../Dashboard/Resources";
import Overview from "../../Dashboard/Overview";
import APIUsage from "../../Dashboard/APIUsage";
import PricingPage from "../../Dashboard/Pricing";
import Grpcs from "../../Dashboard/Grpcs";
import DedicatedNodes from "../../Dashboard/DedicatedNodes";

import NavBarUser from "../Header/Header2";
import CardAdd from "../../Dashboard/CardAdd";
import Credits from "../../Dashboard/Credits";
import Cardprofile from "../../Dashboard/CardProfile";
import SuccessNoti from "../../Dashboard/SuccessNoti";
import NftClaimOffer from "../../Dashboard/NftClaimOffer";
import NftNoClaim from "../../Dashboard/NftNoClaim";
import SuccessOfferClaim from "../../Dashboard/SuccessOfferClaim";
import EligibleLoading from "../../Dashboard/EligibleLoading";
import SidebarWithHeader from "./SidebarWithHeader";
import ErrorOccured from "../../Dashboard/ErrorInClaim";
import JupApi from "../../Dashboard/JupApi";
import IntercomComponent from "../IntercomIntegration/IntercomComponent";
import ImportantNotice from "../../Dashboard/ImportantNotice";
import SupportHiglighter from "../../Dashboard/SupportHighlighter";

import { Box, Flex, Text } from "@chakra-ui/react";

const Sidebar = () => {
  const navigate = useNavigate();
  const { module } = useParams();
  const rewardSysActive = process.env.REACT_APP_REWARD_SYSTEM ?? "inactive";

  let [searchParams, setSearchParams] = useSearchParams();
  const paymentStatus = searchParams.get("success") ?? "unknown";
  const [apKey, setApKey] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  // const [page,setPage] = useState(0);
  const [plan, setPlan] = useState("");
  const [planName, setPlanName] = useState("");
  const [showCard, setShowCard] = useState(false);
  // const stripePromise = loadStripe('pk_test_6pRNASCoBOKtIshFeQd4XMUh');
  // const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

  // const [isOpen, setOpen] = useState(true);
  const [isLoadingData, setLoadingData] = useState(true);
  const [loadedTopBar, setLoadedTopBar] = useState(false);
  const [isFreeUser, setFreeUser] = useState(false);
  const [currentPlan, setCurrentPlan] = useState("");

  const [showDetailsCard, setShowDetailsCard] = useState(false);

  const [paymentPopup, setPaymentPopup] = useState(false);
  const [payMessage, setPaymentMessage] = useState(false);

  const [planDetails, setPlanDetails] = useState([]);
  const [offersLoading, setOffersLoading] = useState("unloaded");
  const [showPlanCard, setShowPlanCard] = useState(false);

  const [currentOfferLoad, setOfferLoad] = useState("unloaded");
  const [currentOffer, setCurrentOffer] = useState(null);
  const [noApplicable, setApplicable] = useState(false);

  const [claimedOffer, setClaimedOffer] = useState(false);

  const [isWalletConnected, setWalletConnected] = useState(false);
  const [connectWalletClicked,setWalletClicked] = useState(false);

  const [readyToLoadChat, setReadytoLoadChat] = useState(false);
  const [showDisclaimer, setShowDisclaimer] = useState(true);
  const [showSupportText, setShowSupportText] = useState(false);

  const displaySupportTextforSecs = (secs) => {
    setShowSupportText(true);
    setTimeout(() => {
      setShowSupportText(false);
    }, secs * 1000);
  }

  const userWallet = useWallet();
  // const openNav = () => {
  //     document.getElementById("mySidenav").style.width = "300px";
  //     setOpen(false);
  // };
  // const closeNav = () => {
  //     document.getElementById("mySidenav").style.width = "0";
  //     setOpen(true);
  // };
  // const setPlanAndShowCard = (plan) => {
  //     setPlan(plan);
  //     setShowCard(true);
  // }

  // const options = {
  //     // passing the client secret obtained from the server
  //     clientSecret: '{{CLIENT_SECRET}}',
  // };
  useEffect(() => {
    //console.log(paymentStatus);
    //console.log(typeof paymentStatus);
    if (paymentStatus === "true") {
      setPaymentMessage(true);
      setPaymentPopup(true);
    } else if (paymentStatus === "false") {
      setPaymentMessage(false);
      setPaymentPopup(true);
    }
    setTimeout(() => {
      setPaymentPopup(false);
    }, 3000);
  }, []);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "auto",
    });
    setLoadingData(true);
    // if (window.innerWidth < 767) {
    //     document.getElementById("menu").style.opacity = "0.0";
    //     document.getElementById("mySidenav").style.width = "0";
    //     setOpen(true);
    // }
  }, [module]);

  useEffect(() => {
    const token = ReactSession.get("xTkn") ?? "";
    if (token !== "") {
      const endPoint = process.env.REACT_APP_AUTH_EP;
      let rqUrl = `${endPoint}auth/profile`;

      axios({
        // Endpoint to get NFTs
        url: rqUrl,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "ngrok-skip-browser-warning": "69420",
        },
      })
        // Handle the response from backend here
        .then((res) => {
          setLoadedTopBar(true);
          //console.log(res.data);
          if (res.data.success === true) {
            setApKey(res.data.result?.api_key);
            setEmail(res.data.result?.email);
            try {
              setName(res.data.result?.name);
            } catch (error) {
              setName("");
            }
            if (!res.data.result?.name) {
              try {
                const detailsCheckTime = ReactSession.get("detCheckTime");
                const dateNow = new Date();
                const dateChecked = new Date(detailsCheckTime);
                if (
                  dateNow.getUTCDate() != dateChecked.getUTCDate() ||
                  dateNow.getUTCMonth() != dateChecked.getUTCMonth() ||
                  dateNow.getUTCFullYear() != dateChecked.getUTCFullYear()
                ) {
                  //show modal for details
                  setShowDetailsCard(true);
                }
              } catch (err) {
                console.error(err);
              }
            }
          } else {
            //remove Token
            ReactSession.set("xTkn", "");
            navigate("/get-api-key");
          }
        })
        // Catch errors if any
        .catch((err) => {
          console.warn(err);
          ReactSession.set("xTkn", "");
          setLoadedTopBar(false);
          navigate("/get-api-key");
        });
    } else {
      ReactSession.set("xTkn", "");
      setReadytoLoadChat(false);
      navigate("/get-api-key");
    }
  }, []);

  const logout = () => {
    ReactSession.set("xTkn", "");
    setReadytoLoadChat(false);
    navigate("/get-api-key");
  };

  useEffect(() => {
    if (userWallet.publicKey && connectWalletClicked === true) {
      // console.log("Pathname where connectWallet runs", window.location.pathname)
      if (window.location.pathname === "/dashboard/overview")
        connectWallet(userWallet.publicKey?.toBase58());
      setWalletConnected(true);
    } else setWalletConnected(false);
  }, [userWallet.publicKey]);

  //fix for when wallet connected on login
  const connectClick = () => {
    if(userWallet.publicKey)
      setWalletClicked(false);
    else
      setWalletClicked(true)
  }

  const connectWallet = async (walletAddress) => {
    const token = ReactSession.get("xTkn") ?? "";
    const rewardSysActive = process.env.REACT_APP_REWARD_SYSTEM ?? "inactive";

    if (token !== "" && rewardSysActive === "active") {
      const endPoint = process.env.REACT_APP_AUTH_EP;
      let rqUrl = `${endPoint}dashboard/reward/eligible`;
      setOffersLoading("loading");
      axios({
        // Endpoint to get NFTs
        url: rqUrl,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "ngrok-skip-browser-warning": "69420",
        },
        params: {
          address: walletAddress,
        },
      })
        // Handle the response from backend here
        .then((res) => {
          setOffersLoading("loaded");
          //console.log(res.data);
          if (res.data.success === true) {
            setPlanDetails(res.data.result ?? []);
            if (res.data.result?.length === 0) setApplicable(true);
          }
        })
        // Catch errors if any
        .catch((err) => {
          console.warn(err);
          //ReactSession.set("xTkn", "");
          setOffersLoading("error");
          setTimeout(() => {
            setOffersLoading("unloaded");
          }, 1000);
          //navigate("/get-api-key");
        });
    } else {
      ReactSession.set("xTkn", "");
      navigate("/get-api-key");
    }
  };
  useEffect(() => {
    if (
      planDetails.length > 0 &&
      offersLoading === "loaded" &&
      currentOffer === null
    )
      setShowPlanCard(true);
  }, [planDetails.length, offersLoading]);

  useEffect(() => {
    if (email !== "" && apKey !== "") {
      setReadytoLoadChat(true);
    }
  }, [email, apKey]);
  

  return (
    <>
      <Loading isLoadingData={isLoadingData} />
      <Box bg={"brand.red"} pt={1} pb={2} position={"fixed"} w={"100%"} zIndex={999}>
      
        <Flex justifyContent={"center"}>
          <Box px={4} pt={"2px"}>
            <Text as="div" textColor={"brand.black"} pt={"2px"} fontWeight={"500"} color={"brand.white"}> Our Twitter handles are compromised, please do not interact with @codinpsycho and @Shyft_to handles. </Text>
          </Box>    
          {/* <Box px={4} pt={"2px"}>
            <Button as={"a"} href={linksConfig.DISCORD_LINK} target="_blank" fontFamily={"heading"} fontWeight={500} size={"xs"} py={2} px={6} borderRadius={"full"}>Join Discord</Button>  
          </Box>     */}
        </Flex>
      </Box>

      {/* <NavBarUser
          openNav={toggleNav}
          isOpen={isOpen}
          loadedTopBar={loadedTopBar}
          email={email}
        /> */}
      <div className={SidebarCss.dark_backdrop_gradient}>
        {paymentPopup && <SuccessNoti payMessage={payMessage} />}
        {showCard && (
          // <Elements stripe={stripePromise}>
          //     <ElementsConsumer>
          //         {({ stripe, elements }) => (
          //             <CardAdd stripe={stripe} elements={elements} setShowCard={setShowCard} setFreeUser={setFreeUser} plan={plan} setCurrentPlan={setCurrentPlan} />
          //         )}
          //     </ElementsConsumer>
          //     {/* <CardAdd setShowCard={setShowCard}/> */}
          // </Elements>
          <CardAdd
            setShowCard={setShowCard}
            setFreeUser={setFreeUser}
            plan={plan}
            planName={planName}
            setCurrentPlan={setCurrentPlan}
          />
        )}

        {showPlanCard && (
          <NftClaimOffer
            setShowPlanCard={setShowPlanCard}
            planDetails={planDetails}
            setPlanDetails={setPlanDetails}
            setCurrentOffer={setCurrentOffer}
            setClaimedOffer={setClaimedOffer}
          />
        )}
        {noApplicable && (
          <NftNoClaim navigate={navigate} setApplicable={setApplicable} />
        )}
        {/* {showDetailsCard && (
          <Cardprofile setShowDetailsCard={setShowDetailsCard} />
        )} */}
        {showDisclaimer && <ImportantNotice setShowDisclaimer={setShowDisclaimer} />}
        {showSupportText && <SupportHiglighter />}
        {claimedOffer && <SuccessOfferClaim />}
        {offersLoading === "error" && <ErrorOccured />}
        {offersLoading === "loading" && <EligibleLoading />}
        {/* <EligibleLoading /> */}
        <SidebarWithHeader
          path={module}
          email={email}
          name={name}
          apKey={apKey}
          logout={logout}
          displaySupportTextforSecs={displaySupportTextforSecs}
        >
          <div>
            {readyToLoadChat && <IntercomComponent userId={apKey} username={name ?? email.split("@")[0]} useremail={email} />}
            {module === "overview" ? (
              <Overview
                setShowCard={setShowCard}
                setLoading={setLoadingData}
                isLoading={isLoadingData}
                currentPlan={currentPlan}
                setCurrentPlan={setCurrentPlan}
                offersLoading={offersLoading}
                planDetails={planDetails}
                currentOfferLoad={currentOfferLoad}
                setOfferLoad={setOfferLoad}
                currentOffer={currentOffer}
                setCurrentOffer={setCurrentOffer}
                isWalletConnected={isWalletConnected}
                setShowPlanCard={setShowPlanCard}
                connectClick={connectClick}
              />
            ) : (
              ""
            )}
            {module === "api_usage" ? (
              <APIUsage setLoading={setLoadingData} isLoading={isLoadingData} />
            ) : (
              ""
            )}
            {module === "resources" ? (
              <Resources
                setLoading={setLoadingData}
                isLoading={isLoadingData}
              />
            ) : (
              ""
            )}
            {module === "pricing" ? (
              <PricingPage
                setShowCard={setShowCard}
                setLoading={setLoadingData}
                isLoading={isLoadingData}
                isFreeUser={isFreeUser}
                setFreeUser={setFreeUser}
                currentPlan={currentPlan}
                setCurrentPlan={setCurrentPlan}
                plan={plan}
                setPlan={setPlan}
                planName={planName}
                setPlanName={setPlanName}
                currentOffer={currentOffer}
                setApplicable={setApplicable}
              />
            ) : (
              ""
            )}
            {module === "credits" ? (
              <Credits setLoading={setLoadingData} isLoading={isLoadingData} />
            ) : (
              ""
            )}
            {module === "billings" ? (
              // <Elements stripe={stripePromise}>
              //     <ElementsConsumer>
              //         {({ stripe, elements }) => (
              //             // <CardAdd stripe={stripe} elements={elements} />
              //             <Billings setLoading={setLoadingData} isLoading={isLoadingData} stripe={stripe} elements={elements} email={email} setShowCard={setShowCard} isFreeUser={isFreeUser} setFreeUser={setFreeUser} currentPlan={currentPlan} setCurrentPlan={setCurrentPlan}/>
              //         )}
              //     </ElementsConsumer>
              // </Elements>
              <Billings
                setLoading={setLoadingData}
                isLoading={isLoadingData}
                email={email}
                setShowCard={setShowCard}
                isFreeUser={isFreeUser}
                setFreeUser={setFreeUser}
                currentPlan={currentPlan}
                setCurrentPlan={setCurrentPlan}
              />
            ) : (
              ""
            )}
            {module === "support" ? (
              <Support setLoading={setLoadingData} isLoading={isLoadingData} />
            ) : (
              ""
            )}
            {module === "grpc" ? (
              <Grpcs 
                setLoading={setLoadingData} 
                isLoading={isLoadingData}
                setShowCard={setShowCard}
                plan={plan}
                setPlan={setPlan}
                planName={planName}
                setPlanName={setPlanName}
                navigate={navigate}
              />
            ) : (
              ""
            )}
            {module === "dedicated-nodes" ? (
              <DedicatedNodes 
                setLoading={setLoadingData} 
                isLoading={isLoadingData}
                setShowCard={setShowCard}
                plan={plan}
                setPlan={setPlan}
                planName={planName}
                setPlanName={setPlanName}
                navigate={navigate}
              />
            ) : (
              ""
            )}
            {module === "jup_api" ? (
              
              <JupApi 
                setLoading={setLoadingData} 
                isLoading={isLoadingData}
                setShowCard={setShowCard}
                plan={plan}
                setPlan={setPlan}
                planName={planName}
                setPlanName={setPlanName}
                navigate={navigate}
              />
            ) : (
              ""
            )}
          </div>
        </SidebarWithHeader>
      </div>
    </>
  );
};

export default Sidebar;
