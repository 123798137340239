import React from 'react';
import Intercom from '@intercom/messenger-js-sdk';

export default function IntercomComponent(props) {
    const intercomId = process.env.REACT_APP_INTERCOM_APP_ID;
    Intercom({
        app_id: intercomId,
        user_id: props.userId, // IMPORTANT: Replace "user.id" with the variable you use to capture the user's ID
        name: props.username, // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
        email: props.useremail, // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email
        created_at: Date.now()/1000, // IMPORTANT: Replace "user.createdAt" with the variable you use to capture the user's sign-up date in a Unix timestamp (in seconds) e.g. 1704067200
    });

    return <div></div>;
}