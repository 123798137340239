import { useEffect } from "react";
import {
  Box,
  Text,
  SimpleGrid,
  Flex,
  VisuallyHidden,
  chakra,
  useColorModeValue,
  Container,
} from "@chakra-ui/react";
import {
  FaDiscord,
  FaTelegramPlane,
} from "react-icons/fa";
import { PopupButton } from "react-calendly";
import { linksConfig } from "../../utils/CommonLinks";


const SocialButton = ({ children, label, href }) => {
  return (
    <chakra.button
      bg={"brand.red"}
      color={"brand.white"}
      rounded={"full"}
      w={8}
      h={8}
      cursor={"pointer"}
      as={"a"}
      href={href}
      target="_blank"
      display={"inline-flex"}
      alignItems={"center"}
      justifyContent={"center"}
      transition={"background 0.3s ease"}
      _hover={{
        bg: "brand.white",
        color: "brand.red",
      }}
    >
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  );
};

const Support = (props) => {
  useEffect(() => {
    setTimeout(() => {
      props.setLoading(false);
    },800)
  }, []);
  return (
    <Container maxW={"8xl"}>
      <Box
        as="div"
        // px={{ base: '16px', md: '24px', xl: '32px' }}
        // py={{ base: '16px', md: '24px' }}
        borderRadius={"14px"}
      >
        <Box>
          <Text
            as="span"
            fontFamily={"heading"}
            color={useColorModeValue("brand.white", "brand.white")}
            fontSize={"2xl"}
            lineHeight={"3xl"}
            fontWeight={"600"}
          >
            Get Support
          </Text>
        </Box>
      </Box>
      <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={10} pt={8}>
        <Box bg="black" px={6} py={6} borderRadius={"2xl"}>
          <Text
            as="div"
            fontFamily={"heading"}
            color={useColorModeValue("brand.white", "brand.white")}
            fontSize={{ base: "18px", lg: "24px" }}
          >
            Connect with us
          </Text>
          <Text
            as="div"
            color={useColorModeValue("brand.grey", "brand.grey")}
            fontSize={{ base: "sm", lg: "16px" }}
            py={4}
          >
            Get in touch with our discord and telegram community and get help
            from our developers who are always here to help you take off.
          </Text>
          <Flex mt={2}>
            <Box pe={2}>
              <SocialButton
                label={"Discord"}
                href={linksConfig.DISCORD_LINK}
              >
                <FaDiscord size={20} />
              </SocialButton>
            </Box>
            <Box pe={2}>
              <SocialButton label={"Telegram"} href={"https://t.me/+elnozTFe3OY0NDA1"}>
                <FaTelegramPlane size={20} />
              </SocialButton>
            </Box>
          </Flex>
        </Box>
        <Box bg="black" px={6} py={6} borderRadius={"2xl"}>
          <Text
            as="div"
            fontFamily={"heading"}
            color={useColorModeValue("brand.white", "brand.white")}
            fontSize={{ base: "18px", lg: "24px" }}
          >
            Write to us
          </Text>
          <Text
            as="div"
            color={useColorModeValue("brand.grey", "brand.grey")}
            fontSize={{ base: "sm", lg: "16px" }}
            py={4}
          >
            We would love to hear from you. Feel free to drop your queries,
            requirements or feedback that would help us to improve our product
            for you at{" "}
            <Text
              as="a"
              href="mailto:support@shyft.to"
              color={"brand.yellow"}
              _hover={{ color: "brand.white" }}
            >
              support@shyft.to
            </Text>
          </Text>
        </Box>
        <Box bg="black" px={6} py={6} borderRadius={"2xl"}>
          <Text
            as="div"
            fontFamily={"heading"}
            color={useColorModeValue("brand.white", "brand.white")}
            fontSize={{ base: "18px", lg: "24px" }}
          >
            Get in Touch
          </Text>
          <Text
            as="div"
            color={useColorModeValue("brand.grey", "brand.grey")}
            fontSize={{ base: "sm", lg: "16px" }}
            py={4}
          >
            We are always happy to discuss how our APIs can help you accelerate.
            Book a demo call at your convenience.
          </Text>
          {/* <Button size={"sm"} px={8} py={5} borderRadius={"full"} fontSize={{base: "sm"}} bg={"red"} color={"white"} _hover={{bg:"brand.white", color: "brand.red" }}>Book a call</Button> */}
          <Box py={2}>
            <PopupButton
              url="https://calendly.com/shyft-to"
              rootElement={document.getElementsByTagName("body")[0]}
              text="Book a Call"
              className={"calendly_button"}
            />
          </Box>
        </Box>
      </SimpleGrid>
    </Container>
  );
};

export default Support;
