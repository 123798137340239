import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const RedirectDashboard = () => {
    const navigate = useNavigate();
    useEffect(() => {
        navigate("/dashboard/overview");
    })
    return ( <div></div> );
}
 
export default RedirectDashboard;