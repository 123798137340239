import { useState, useEffect } from "react";
import { ReactSession } from "react-client-session";
import { Link, useNavigate, useSearchParams, useLocation } from "react-router-dom";
import axios from "axios";
import {
  Box,
  Flex,
  Select,
  Stack,
  Text,
  Button,
  Heading,
  Image,
  useColorModeValue,
  HStack,
  Link as ChakraLink,
  Container,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { PopupButton } from "react-calendly";
import { BeatLoader } from 'react-spinners';
import { FaCheck } from "react-icons/fa";
import { ImCross } from "react-icons/im";

import { formatCreditUsage } from "../../utils/CreditFormat";
import { linksConfig } from "../../utils/CommonLinks";

const PlanWeight = {
  HACK: 1,
  LAUNCH: 2,
  SCALE: 3
}

const PlanIds = {
  hack: "667d12f8a5f239abc0f8db27",
  launch: "667d1ab8a5f239abc0f8db28",
  scale: "65cdd451f6bf8bf2e8ed1851"
}

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const PricingPage = (props) => {
  const navigate = useNavigate();
  const [queryParameters] = useSearchParams();
  const  {pathname, hash, key } = useLocation();
  const [offerData, setOfferData] = useState([]);

  const [showUpgradeCard, setShowUpgradeCard] = useState(false);
  const [showDowngradeCard, setShowDowngradeCard] = useState(false);
  const [updateCardType,setUpdateCardType] = useState("");
  const [updatePlanType,setUpdatePlanType] = useState("");
  const [updatePlanId,setUpdatePlanId] = useState("");
  const [updateStatus,setUpdateStatus] = useState("NO_ACTION");
  const [updateStatusMessage,setUpdateStatusMessage] = useState("");
  const [prorateValue,setProrateValue] = useState("--");
  const [prorateCurrency,setProrateCurrency] = useState("$");
  const [prorateLoading,setProrateLoading] = useState(false);
  const [currentSubscriptionEndDate,setCurrentSubscriptionEndDate] = useState("");


  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash !== '') {
      //window.scrollTo(0, 0);
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element && id === 'nft_offers')  {
          //element.scrollIntoView({block: "center"});
          const clickElem = document.getElementById('offer_button');
          clickElem.click();
        }
      }, 1000);
    }
  }, [pathname, hash, key]);
  
  // const [pointerToggle,setPointerToggle] = useState(false);

  useEffect(() => {
    props.setApplicable(false);
  }, []);

  const [errOcc, setErrOcc] = useState(false);
  const [data, setData] = useState(null);
  const [freePlanSpecs, setFreePlanSpecs] = useState({
    name: "FREE",
    credits: 500000,
    amount: {
      usd: 0,
      inr: 0,
    },
    rateLimitPerSecond: 1,
    isHardStopActivatedOnPlan: true,
  });
  const [hackPlanSpecs, setHackPlanSpecs] = useState({
    name: "HACK",
    credits: 10000000,
    amount: {
      usd: 49,
      inr: 4089,
    },
    rateLimitPerSecond: 10,
    isHardStopActivatedOnPlan: true,
  });
  const [launchPlanSpecs, setLaunchPlanSpecs] = useState({
    name: "LAUNCH",
    credits: 100000000,
    amount: {
      usd: 199,
      inr: 16599,
    },
    rateLimitPerSecond: 30,
    isHardStopActivatedOnPlan: false,
    perCreditChargeAfterExceedingUsage: {
      usd: 0.00033,
      inr: 0.0016,
    },
  });
  const [scalePlanSpecs, setScalePlanSpecs] = useState({
    name: "SCALE",
    credits: 500000000,
    amount: {
      usd: 275,
      inr: 22320,
    },
    rateLimitPerSecond: 100,
    isHardStopActivatedOnPlan: false,
    perCreditChargeAfterExceedingUsage: {
      usd: 0.000165,
      inr: 0.0008,
    },
  });

  const selectPlanAndPay = (planName,plan) => {
    props.setPlan(plan);
    props.setPlanName(planName);
    props.setShowCard(true);
  };

  const setProrateChargeForToday = async (planId) => {
    const token = ReactSession.get("xTkn") ?? "";
    // const isFree = ReactSession.get("ifr") ?? "unknown";
    //callAPI and setProrate charge
    if (token !== "") {
      const endPoint = process.env.REACT_APP_AUTH_EP;
      axios({
        url: `${endPoint}dashboard/get_prorate_value`,
        method: "GET",
        headers: {
          "Authorization": `Bearer ${token}`,
          "ngrok-skip-browser-warning": "69420",
          "Content-Type": "application/json",
        },
        params: {
          plan_id: planId,
        },
      })
        .then((res) => {
          if (res.data.success === true) {
            setProrateValue(res.data.result.prorated_value);
            setProrateCurrency(res.data.result.currency);
          }
        })
        // Catch errors if any
        .catch((err) => {
          console.warn(err);
          
        });
    }
  }
  const selectUpdatePlan = (planId, planName, type) => {
    setUpdateStatusMessage("");
    setUpdatePlanType(planName);
    setUpdatePlanId(planId);
    if(type === 'DOWN'){
      setUpdateCardType('DOWN');
      setShowDowngradeCard(true);
    } else {
      setUpdateCardType('UP');
      setProrateChargeForToday(planId);
      setShowUpgradeCard(true);
    }
    
  };

  useEffect(() => {
    const token = ReactSession.get("xTkn") ?? "";
    const isFree = ReactSession.get("ifr") ?? "unknown";
    //console.log(isFree);
    if (isFree === "Free") props.setFreeUser(true);
    else props.setFreeUser(false);
    if (token !== "") {
      const endPoint = process.env.REACT_APP_AUTH_EP;
      axios({
        url: `${endPoint}pricing`,
        method: "GET",
        headers: {
          "ngrok-skip-browser-warning": "69420",
        },
      })
        .then((res) => {
          if (res.data.success === true) {
            // setFreePlanSpecs(res.data.result.plans.find(plan => plan.name === 'FREE'));
            // setHackPlanSpecs(res.data.result.plans.find(plan => plan.name === 'HACK'));
            // setLaunchPlanSpecs(res.data.result.plans.find(plan => plan.name === 'LAUNCH'));
            // setScalePlanSpecs(res.data.result.plans.find(plan => plan.name === 'SCALE'));
            setData(res.data);
          } else {
            setErrOcc(true);
          }
        })
        // Catch errors if any
        .catch((err) => {
          console.warn(err);
          setErrOcc(true);
          props.setLoading(false);
        });
    } else {
      navigate("/get-api-key");
    }
  }, []);

  useEffect(() => {
    const token = ReactSession.get("xTkn") ?? "";
    const expiryDate = ReactSession.get("ceDt") ?? "";
    setCurrentSubscriptionEndDate(expiryDate);
    if (token !== "" && props.currentPlan === "") {
      const endPoint = process.env.REACT_APP_AUTH_EP;
      axios({
        url: `${endPoint}dashboard/overview`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "ngrok-skip-browser-warning": "69420",
        },
      })
        .then((res) => {
          if (res.data.success === true) {
            props.setCurrentPlan(res.data.result.current_plan ?? "FREE");
            if (res.data.result.current_plan === "FREE")
              ReactSession.set("ifr", "Free");
            else ReactSession.set("ifr", "Paid");
            ReactSession.set("ceDt", `${new Date(res.data.result.cycle_end).getUTCDate()}- 
                        ${months[new Date(res.data.result.cycle_end).getUTCMonth()]}
                        ${new Date(res.data.result.cycle_end).getUTCFullYear()}`);
            setCurrentSubscriptionEndDate(`${new Date(res.data.result.cycle_end).getUTCDate()}- 
                        ${months[new Date(res.data.result.cycle_end).getUTCMonth()]}
                        ${new Date(res.data.result.cycle_end).getUTCFullYear()}`);
          }
        })
        // Catch errors if any
        .catch((err) => {
          console.warn(err);
          // setErrOcc(true);
          // props.setLoading(false);
        });
    }
  }, []);

  useEffect(() => {
    if (data !== null || errOcc === true) {
      props.setLoading(false);

      // setTimeout(() => {
      //     const isUserOnFreePlan = ReactSession.get("ifr") ?? "unknown";
      //     if(props.currentPlan === "FREE")
      //     {
      //         console.log(props.currentPlan);
      //         const planToActivate = queryParameters.get("activatePlan");
      //         selectPlanAndPay(planToActivate.toUpperCase());
      //     }
      // }, 500);
    }
  }, [data, errOcc]);

  useEffect(() => {
    //console.log(props.currentPlan)
    setTimeout(() => {
      const isUserOnFreePlan = ReactSession.get("ifr") ?? "unknown";
      //console.log(isUserOnFreePlan)
      const planToActivate = queryParameters.get("activatePlan") ?? null;
      if (isUserOnFreePlan?.toUpperCase() === "FREE" && planToActivate) {
        //console.log(props.currentPlan);

        selectPlanAndPay(planToActivate.toUpperCase());
      }
    }, 100);
  }, [props.isLoading]);

  useEffect(() => {
    const token = ReactSession.get("xTkn") ?? "";
    const rewardSysActive = process.env.REACT_APP_REWARD_SYSTEM ?? "inactive";
    if (token !== "" && rewardSysActive === "active") {
      const endPoint = process.env.REACT_APP_AUTH_EP;
      axios({
        url: `${endPoint}dashboard/reward/all`,
        method: "GET",
        // headers: {
        //     'Authorization': `Bearer ${token}`,
        //     "ngrok-skip-browser-warning": "69420"
        // }
      })
        .then((res) => {
          if (res.data.success === true) {
            setOfferData(res.data.result ?? []);
          }
        })
        // Catch errors if any
        .catch((err) => {
          console.warn(err);
          // setErrOcc(true);
          // props.setLoading(false);
        });
    }
  }, []);

  const changePlanNow = (type) => {
    setUpdateStatus("CHANGING");
    setUpdateStatusMessage("");
    const endPoint = process.env.REACT_APP_AUTH_EP;
    const token = ReactSession.get("xTkn") ?? "";
    if(type === "UP") {
      axios({
        url: `${endPoint}dashboard/upgrade_plan`,
        method: "POST",
        headers: {
            'Authorization': `Bearer ${token}`,
            "ngrok-skip-browser-warning": "69420",
        },
        data: {
            plan_id: updatePlanId
        }
    })
        .then((res) => {
            if (res.data.success === true) {
              setUpdateStatus("CHANGED");
              setUpdatePlanId('');
                // props.setCurrentPlan("FREE");
                // ReactSession.set("ifr", "FREE");
            }
            else {
              setUpdateStatusMessage("Error while changing plan. Please reachout to us.");
              setUpdatePlanId('');
              setUpdateStatus("UNCHANGED");
            }
            setTimeout(() => {
              setUpdateStatus("NO_ACTION");
              setShowUpgradeCard(false);
              navigate(0);
            }, 1000);
        })
        // Catch errors if any
        .catch((err) => {
            //console.warn(err);
            if(err?.response?.data?.error.startsWith("You cannot migrate a subscription")) {
              setUpdateStatusMessage("You have already switched your plan. Please reach out to us for more info.");
            }
            setUpdateStatusMessage("Error while changing plan. Please reachout to us.");
            setUpdatePlanId('');
            setUpdateStatus("UNCHANGED");
            setTimeout(() => {
              setUpdateStatus("NO_ACTION");
              setShowUpgradeCard(false);
            }, 2500);
        });
    } else {
      axios({
        url: `${endPoint}dashboard/downgrade_plan`,
        method: "POST",
        headers: {
            'Authorization': `Bearer ${token}`,
            "ngrok-skip-browser-warning": "69420",
        },
        data: {
            plan_id: updatePlanId
        }
    })
        .then((res) => {
            if (res.data.success === true) {
              setUpdateStatus("CHANGED");
              setUpdatePlanId('');
                // props.setCurrentPlan("FREE");
                // ReactSession.set("ifr", "FREE");
            }
            else {
              setUpdateStatusMessage("Error while changing plan. Please reachout to us.");
              setUpdatePlanId('');
              setUpdateStatus("UNCHANGED");
            }
            setTimeout(() => {
              setUpdateStatus("NO_ACTION");
              setShowDowngradeCard(false);
            }, 1000);
        })
        // Catch errors if any
        .catch((err) => {
            //console.warn(err);
            if(err?.response?.data?.error.startsWith("You cannot migrate a subscription"))
              setUpdateStatusMessage("You have already switched your plan. Please reach out to us for more info.");
            else
              setUpdateStatusMessage("Error while changing plan. Please reach out to us on dashboard support.");
            setUpdatePlanId('');
            setUpdateStatus("UNCHANGED");
            setTimeout(() => {
              setUpdateStatus("NO_ACTION");
              setShowDowngradeCard(false);
            }, 4000);
        });
    }
    
}

  return (
    <div>
      <div>
        <Modal
          isOpen={showUpgradeCard}
          onClose={() => setShowUpgradeCard(false)}
          blockScrollOnMount={true}
        >
          <ModalOverlay />
          <ModalContent
            bg={"brand.black"}
            border={"1px solid"}
            borderColor={"brand.midGrey"}
            borderRadius={"xl"}
          >
            <ModalCloseButton />
            <ModalBody py={4}>
              <Text
                as="div"
                fontSize={{ base: "md", md: "md" }}
                fontWeight={"600"}
                color={"brand.white"}
                textAlign={"center"}
                fontFamily={"heading"}
              >
                Are you sure you want to upgrade to{" "}
                <Text as="span" color={"brand.red"}>
                  {updatePlanType}
                </Text>{" "}
                plan?
              </Text>
              <Text
                as="div"
                fontSize={{ base: "xs", md: "sm" }}
                fontWeight={"400"}
                color={"brand.white"}
                textAlign={"center"}
                mt={4}
              >
                You will be billed{" "}
                <Text as="span" color={"brand.yellow"}>
                  {prorateCurrency === 'inr'?'₹':'$'}{prorateValue}
                </Text>
                .
              </Text>
              <Text
                as="div"
                fontSize={{ base: "xs", md: "xs" }}
                fontWeight={"600"}
                color={"brand.red"}
                textAlign={"start"}
                mt={4}
              >
                {updateStatusMessage}
              </Text>
            </ModalBody>
            <ModalFooter>
              {updateStatus === "NO_ACTION" && (
                <Flex width={"100%"} justifyContent={"center"}>
                  <Button
                    colorScheme="blue"
                    mr={3}
                    onClick={() => {
                      changePlanNow("UP");
                    }}
                    color={"brand.dark"}
                    bg={"brand.white"}
                    _hover={{
                      bg: "brand.black",
                      color: "brand.white",
                    }}
                    px={"20px"}
                    fontFamily={"heading"}
                    fontSize={"12px"}
                    lineHeight={"12px"}
                    size={"sm"}
                    borderRadius={"full"}
                  >
                    Yes
                  </Button>
                  <Button
                    colorScheme="blue"
                    mr={3}
                    onClick={() => {
                      setShowUpgradeCard(false);
                    }}
                    color={"brand.dark"}
                    bg={"brand.white"}
                    _hover={{
                      bg: "brand.black",
                      color: "brand.white",
                    }}
                    px={"20px"}
                    fontFamily={"heading"}
                    fontSize={"12px"}
                    lineHeight={"12px"}
                    size={"sm"}
                    borderRadius={"full"}
                  >
                    No
                  </Button>
                </Flex>
              )}
              {updateStatus === "CHANGING" && (
                <Flex w={"100%"} justifyContent={"center"} height={"24px"}>
                  <BeatLoader color="#fff" loading={true} size={10} />
                </Flex>
              )}
              {updateStatus === "CHANGED" && (
                <Flex w={"100%"} justifyContent={"center"} height={"24px"}>
                  <FaCheck color="#39e75f" />
                </Flex>
              )}
              {updateStatus === "UNCHANGED" && (
                <Flex w={"100%"} justifyContent={"center"} height={"24px"}>
                  <ImCross color="#e3242b" />
                </Flex>
              )}
            </ModalFooter>
          </ModalContent>
        </Modal>
        <Modal
          isOpen={showDowngradeCard}
          onClose={() => setShowDowngradeCard(false)}
          blockScrollOnMount={true}
        >
          <ModalOverlay />
          <ModalContent
            bg={"brand.black"}
            border={"1px solid"}
            borderColor={"brand.midGrey"}
            borderRadius={"xl"}
          >
            <ModalCloseButton />
            <ModalBody py={4}>
              <Text
                as="div"
                fontSize={{ base: "md", md: "md" }}
                fontWeight={"600"}
                color={"brand.white"}
                textAlign={"center"}
                fontFamily={"heading"}
              >
                Are you sure you want to switch to{" "}
                <Text as="span" color={"brand.red"}>
                  {updatePlanType}
                </Text>{" "}
                plan?
              </Text>
              <Text
                as="div"
                fontSize={{ base: "xs", md: "sm" }}
                fontWeight={"400"}
                color={"brand.white"}
                textAlign={"center"}
                mt={4}
              >
                {/* Note: The new plan will be applicable from the next billing cycle. */}
                The new plan will be applicable from{" "}
                {currentSubscriptionEndDate == "" ? (
                  " the next billing cycle"
                ) : (
                  <Text as="span" color={"brand.yellow"}>
                    {currentSubscriptionEndDate}
                  </Text>
                )}
                .
              </Text>
              <Text
                as="div"
                fontSize={{ base: "xs", md: "xs" }}
                fontWeight={"600"}
                color={"brand.red"}
                textAlign={"start"}
                mt={4}
              >
                {updateStatusMessage}
              </Text>
            </ModalBody>
            <ModalFooter>
              {updateStatus === "NO_ACTION" && (
                <Flex width={"100%"} justifyContent={"center"}>
                  <Button
                    colorScheme="blue"
                    mr={3}
                    onClick={() => {
                      changePlanNow("DOWN");
                    }}
                    color={"brand.dark"}
                    bg={"brand.white"}
                    _hover={{
                      bg: "brand.black",
                      color: "brand.white",
                    }}
                    px={"20px"}
                    fontFamily={"heading"}
                    fontSize={"12px"}
                    lineHeight={"12px"}
                    size={"sm"}
                    borderRadius={"full"}
                  >
                    Yes
                  </Button>
                  <Button
                    colorScheme="blue"
                    mr={3}
                    onClick={() => {
                      setShowDowngradeCard(false);
                    }}
                    color={"brand.dark"}
                    bg={"brand.white"}
                    _hover={{
                      bg: "brand.black",
                      color: "brand.white",
                    }}
                    px={"20px"}
                    fontFamily={"heading"}
                    fontSize={"12px"}
                    lineHeight={"12px"}
                    size={"sm"}
                    borderRadius={"full"}
                  >
                    No
                  </Button>
                </Flex>
              )}
              {updateStatus === "CHANGING" && (
                <Flex w={"100%"} justifyContent={"center"} height={"24px"}>
                  <BeatLoader color="#fff" loading={true} size={10} />
                </Flex>
              )}
              {updateStatus === "CHANGED" && (
                <Flex w={"100%"} justifyContent={"center"} height={"24px"}>
                  <FaCheck color="#39e75f" />
                </Flex>
              )}
              {updateStatus === "UNCHANGED" && (
                <Flex w={"100%"} justifyContent={"center"} height={"24px"}>
                  <ImCross color="#e3242b" />
                </Flex>
              )}
            </ModalFooter>
          </ModalContent>
        </Modal>
        <div>
          {!props.isLoading && data !== null && (
            <Container maxW={"8xl"}>
              <Box
                as="div"
                // px={{ base: '16px', md: '24px', xl: '32px' }}
                // py={{ base: '16px', md: '24px' }}
                borderRadius={"14px"}
              >
                <Box>
                  <Text
                    as={"div"}
                    fontFamily={"heading"}
                    color={"brand.white"}
                    fontSize={"2xl"}
                    lineHeight={"3xl"}
                    fontWeight={"600"}
                    position={"relative"}
                  >
                    Manage your Subscription
                    {/* <ChakraLink
                      id="offer_button"
                      href="/dashboard/pricing#nft_offers"
                      fontFamily={"heading"}
                      fontSize={{ base: "12px" }}
                      fontWeight={"300"}
                      marginTop={"8px"}
                      marginLeft={"10px"}
                      backgroundColor={"brand.red"}
                      py={0.5}
                      px={2.5}
                      borderRadius={6}
                      position={"absolute"}
                      _hover={{
                        textDecoration: "none",
                        color: "brand.white",
                      }}
                    >
                      View Offers
                    </ChakraLink> */}
                  </Text>
                  {props.currentPlan === "FREE" ? (
                    <Text
                      as={"div"}
                      fontSize={{ base: "14px", lg: "md" }}
                      fontWeight={"400"}
                      color={"brand.lighterWhite"}
                      pt={{ base: "4px", md: "6px" }}
                    >
                      Get your super powers with simple and transparent plans.
                    </Text>
                  ) : (
                    <Text
                      as={"div"}
                      fontSize={{ base: "14px", lg: "md" }}
                      fontWeight={"400"}
                      color={"brand.lighterWhite"}
                      pt={{ base: "4px", md: "6px" }}
                    >
                      {/* Reach out to us on */}{" "}
                      {/* <Text
                        as={"a"}
                        href="https://discord.gg/8JyZCjRPmr"
                        target="_blank"
                        color={"brand.yellow"}
                        _hover={{ color: "brand.red" }}
                      >
                        discord
                      </Text>{" "}
                      to upgrade your plan. */}
                    </Text>
                  )}
                </Box>
              </Box>
              <Flex as="div" pt={{ base: 6, md: 8 }} flexWrap={"wrap"}>
                      <Box
                          border={'2px solid'}
                          borderColor="brand.lightGray"
                          bg={"#000"}
                          px={"16px"}
                          py={"8px"}
                          me={"8px"}
                          borderRadius={"8px"}
                          mt={{base: "6px", md: "0px"}}
                      >
                          <Box as="div" color="brand.midGrey" fontSize={{ base: 'xs', md: 'md' }}> 1 API call =  100 credits</Box>
                      </Box>
                      <Box
                          border={'2px solid'}
                          borderColor="brand.lightGray"
                          bg={"#000"}
                          px={"16px"}
                          py={"8px"}
                          borderRadius={"8px"}
                          me={"8px"}
                          mt={{base: "6px", md: "0px"}}
                      >
                          <Box as="div" color="brand.midGrey" fontSize={{ base: 'xs', md: 'md' }}> 1 RPC call =  1 credit</Box>
                      </Box>
                      <Box
                          border={'2px solid'}
                          borderColor="brand.lightGray"
                          bg={"#000"}
                          px={"16px"}
                          py={"8px"}
                          borderRadius={"8px"}
                          me={"8px"}
                          mt={{base: "6px", md: "0px"}}
                      >
                          <Box as="div" color="brand.midGrey" fontSize={{ base: 'xs', md: 'md' }}> 1 callback =  1 credit</Box>
                      </Box>
                  </Flex>
              <Box mt={2}>
                <Stack
                  as="div"
                  spacing={{ base: 5 }}
                  pt={{ base: 3, md: 3 }}
                  pb={{ base: 6, md: 6 }}
                >
                  <Box
                    as="div"
                    border={"2px solid"}
                    borderColor={
                      props.currentPlan === "FREE"
                        ? "brand.red"
                        : "brand.lightGray"
                    }
                    boxShadow={
                      props.currentPlan === "FREE"
                        ? "0px 2px 12px #ff000099"
                        : ""
                    }
                    borderRadius={"xl"}
                    py={8}
                    px={8}
                    bg={"black"}
                  >
                    <Flex wrap={"wrap"} justifyContent={"space-between"}>
                      <Heading
                        as="h3"
                        color={"brand.white"}
                        fontWeight={"600"}
                        fontSize={"3xl"}
                        mb={0}
                      >
                        Free{" "}
                        <Text
                          as="span"
                          fontSize={"sm"}
                          fontWeight={"light"}
                          fontFamily={"body"}
                        >
                          to get started
                        </Text>
                      </Heading>
                      {props.currentPlan === "FREE" && (
                        <Text
                          as={"div"}
                          bg={"brand.red"}
                          color={"brand.white"}
                          border={"1px solid"}
                          borderColor={"brand.red"}
                          w={{ base: "160px" }}
                          textAlign={"center"}
                          rounded={"full"}
                          py={{ base: "8px" }}
                          // size={"sm"}
                          fontFamily={"heading"}
                          fontWeight={500}
                          fontSize={{ base: "xs", md: "sm" }}
                          _hover={{
                            textDecoration: "none",
                            bg: "brand.red",
                          }}
                          display={{ base: "none", md: "initial" }}
                        >
                          Current Plan
                        </Text>
                      )}
                    </Flex>
                    <Flex pt={{ base: 4, md: 8 }} flexWrap={"wrap"}>
                      <Stack
                        direction={"row"}
                        py={1}
                        mr={{ base: "40px", md: "68px", lg: "80px" }}
                        mt={{ base: "12px", lg: "0px" }}
                      >
                        <Box>
                          <Image
                            src="/assets/Icons/vector-tick.svg"
                            alt="tick"
                          />
                        </Box>
                        <Text
                          as={"span"}
                          fontFamily={"Jost"}
                          fontSize={{ base: "14px", lg: "md" }}
                          color="brand.midGrey"
                          fontWeight={"500"}
                        >
                          {formatCreditUsage(freePlanSpecs.credits)} credits
                          every month
                        </Text>
                      </Stack>
                      <Stack
                        direction={"row"}
                        py={1}
                        mr={{ base: "40px", md: "68px", lg: "80px" }}
                        mt={{ base: "12px", lg: "0px" }}
                      >
                        <Box>
                          <Image
                            src="/assets/Icons/vector-tick.svg"
                            alt="tick"
                          />
                        </Box>
                        <Text
                          as={"span"}
                          fontFamily={"Jost"}
                          fontSize={{ base: "14px", lg: "md" }}
                          color="brand.midGrey"
                          fontWeight={"500"}
                        >
                          {freePlanSpecs.rateLimitPerSecond} API/GraphQL req/sec
                        </Text>
                      </Stack>
                      <Stack
                        direction={"row"}
                        py={1}
                        mr={{ base: "40px", md: "68px", lg: "100px" }}
                        mt={{ base: "12px", lg: "0px" }}
                      >
                        <Box>
                          <Image
                            src="/assets/Icons/vector-tick.svg"
                            alt="tick"
                          />
                        </Box>
                        <Text
                          as={"span"}
                          fontFamily={"Jost"}
                          fontSize={{ base: "14px", lg: "md" }}
                          color="brand.midGrey"
                          fontWeight={"500"}
                        >
                          {/* {freePlanSpecs.rpcLimitPerSecond} RPC/DAS req/sec */}
                          30 RPC req/sec
                        </Text>
                      </Stack>
                      <Stack
                        direction={"row"}
                        py={1}
                        mr={{ base: "40px", md: "68px", lg: "80px" }}
                        mt={{ base: "12px", lg: "0px" }}
                      >
                        <Box>
                          <Image
                            src="/assets/Icons/vector-tick.svg"
                            alt="tick"
                          />
                        </Box>
                        <Text
                          as={"span"}
                          fontFamily={"Jost"}
                          fontSize={{ base: "14px", lg: "md" }}
                          color="brand.midGrey"
                          fontWeight={"500"}
                        >
                          {" "}
                          No Auto-Scaling (Capped at{" "}
                          {formatCreditUsage(freePlanSpecs.credits)})
                        </Text>
                      </Stack>
                      <Stack
                        direction={"row"}
                        py={1}
                        mr={{ base: "40px", md: "8px", lg: "0px" }}
                        mt={{ base: "12px", lg: "0px" }}
                      >
                        <Box>
                          <Image
                            src="/assets/Icons/vector-tick.svg"
                            alt="tick"
                          />
                        </Box>
                        <Text
                          as={"span"}
                          fontFamily={"Jost"}
                          fontSize={{ base: "14px", lg: "md" }}
                          color="brand.midGrey"
                          fontWeight={"500"}
                        >
                          {" "}
                          No Callbacks
                        </Text>
                      </Stack>
                    </Flex>
                  </Box>
                  <Flex
                    justifyContent={{
                      base: "space-around",
                      xl: "space-between",
                    }}
                    flexWrap={"wrap"}
                  >
                    <Stack
                      border={"2px solid"}
                      borderColor={
                        props.currentPlan === "HACK"
                          ? "brand.red"
                          : "brand.lightGray"
                      }
                      boxShadow={
                        props.currentPlan === "HACK"
                          ? "0px 2px 12px #ff000099"
                          : ""
                      }
                      py={8}
                      px={8}
                      spacing={8}
                      width="400px"
                      bg={"black"}
                      mt={6}
                      className="each_plan"
                    >
                      <Box>
                        <Image
                          src="/assets/paper_plane.png"
                          alt="plane"
                          margin={"0 auto"}
                        />
                      </Box>
                      <Text
                        as={"span"}
                        textAlign={"center"}
                        fontSize={"18px"}
                        color={"brand.white"}
                      >
                        {hackPlanSpecs.name ?? "--"}
                      </Text>
                      <Flex justifyContent={"center"}>
                        <Box px={4}>
                          <Text
                            as={"span"}
                            fontSize={"4xl"}
                            fontWeight={"700"}
                            color={"brand.white"}
                            fontFamily={"heading"}
                          >
                            ${hackPlanSpecs.amount.usd ?? "49"}
                            {/* $29 */}
                          </Text>
                        </Box>
                        <Box
                          px={4}
                          fontSize={"1xl"}
                          fontWeight={"300"}
                          color={"brand.white"}
                          fontFamily={"heading"}
                          py={0}
                          borderLeft={"2px solid"}
                          borderColor={"brand.white"}
                          height={"50px"}
                        >
                          <Text as={"div"}>PER</Text>
                          <Text as={"div"}>MONTH</Text>
                        </Box>
                      </Flex>
                      <Stack
                        spacing={4}
                        className="plan_bullet_points"
                        margin={"0 auto"}
                      >
                        <Stack direction={"row"}>
                          <Box>
                            <Image
                              src="/assets/Icons/vector-tick.svg"
                              alt="tick"
                            />
                          </Box>
                          <Text
                            as={"span"}
                            fontFamily={"Jost"}
                            fontSize={{ base: "14px", lg: "md" }}
                            color="brand.yellow"
                            fontWeight={"500"}
                          >
                            {" "}
                            {formatCreditUsage(hackPlanSpecs.credits)} credits
                            every month
                          </Text>
                        </Stack>
                        <Stack direction={"row"}>
                          <Box>
                            <Image
                              src="/assets/Icons/vector-tick.svg"
                              alt="tick"
                            />
                          </Box>
                          <Text
                            as={"div"}
                            fontFamily={"Jost"}
                            fontSize={{ base: "14px", lg: "md" }}
                            color="brand.midGrey"
                            fontWeight={"500"}
                          >
                            {" "}
                            {hackPlanSpecs.rateLimitPerSecond} API/GraphQL
                            req/sec
                          </Text>
                        </Stack>
                        <Stack direction={"row"}>
                          <Box>
                            <Image
                              src="/assets/Icons/vector-tick.svg"
                              alt="tick"
                            />
                          </Box>
                          <Text
                            as={"div"}
                            fontFamily={"Jost"}
                            fontSize={{ base: "14px", lg: "md" }}
                            color="brand.midGrey"
                            fontWeight={"500"}
                          >
                            {" "}
                            {/* {hackPlanSpecs.rpcLimitPerSecond} RPC/DAS req/sec */}
                            50 RPC req/sec
                          </Text>
                        </Stack>
                        {hackPlanSpecs.isHardStopActivatedOnPlan ? (
                          <Stack direction={"row"}>
                            <Box>
                              <Image
                                src="/assets/Icons/vector-tick.svg"
                                alt="tick"
                              />
                            </Box>
                            <Text
                              as={"div"}
                              fontFamily={"Jost"}
                              fontSize={{ base: "14px", lg: "md" }}
                              color="brand.midGrey"
                              fontWeight={"500"}
                            >
                              {" "}
                              No Auto-Scaling (Capped at{" "}
                              {formatCreditUsage(Number(hackPlanSpecs.credits))}
                              )
                            </Text>
                          </Stack>
                        ) : (
                          <Stack direction={"row"}>
                            <Box>
                              <Image
                                src="/assets/Icons/vector-tick.svg"
                                alt="tick"
                              />
                            </Box>
                            <Text
                              as={"div"}
                              fontFamily={"Jost"}
                              fontSize={{ base: "14px", lg: "md" }}
                              color="brand.midGrey"
                              fontWeight={"500"}
                            >
                              $
                              {
                                hackPlanSpecs.perCreditChargeAfterExceedingUsage
                                  .usd
                              }{" "}
                              for each additional 100 credits, after{" "}
                              {formatCreditUsage(hackPlanSpecs.credits)}
                            </Text>
                          </Stack>
                        )}
                        <Stack direction={"row"}>
                          <Box>
                            <Image
                              src="/assets/Icons/vector-tick.svg"
                              alt="tick"
                            />
                          </Box>
                          <Text
                            as={"div"}
                            fontFamily={"Jost"}
                            fontSize={{ base: "14px", lg: "md" }}
                            color="brand.midGrey"
                            fontWeight={"500"}
                          >
                            {" "}
                            Callbacks Enabled
                          </Text>
                        </Stack>
                      </Stack>
                      <Stack>
                        {props.currentPlan === "FREE" ? (
                          <Button
                            size={"sm"}
                            width={"40"}
                            height={"10"}
                            margin={"0 auto"}
                            variant={"outline"}
                            borderColor={"brand.red"}
                            borderRadius={"full"}
                            className="each_plan_button"
                            fontFamily={"heading"}
                            fontWeight={"500"}
                            mt={4}
                            onClick={() =>
                              selectPlanAndPay("HACK", PlanIds.hack)
                            }
                          >
                            Select Plan
                          </Button>
                        ) : props.currentPlan === "HACK" ? (
                          <Button
                            size={"sm"}
                            width={"40"}
                            height={"10"}
                            margin={"0 auto"}
                            marginTop={4}
                            variant={"outline"}
                            borderColor={"brand.red"}
                            borderRadius={"full"}
                            className="each_plan_button"
                            bgColor={"brand.red"}
                            fontFamily={"heading"}
                            fontWeight={"500"}
                          >
                            Current Plan
                          </Button>
                        ) : PlanWeight[props.currentPlan] >
                          PlanWeight["HACK"] ? (
                          <Button
                            size={"sm"}
                            width={"40"}
                            height={"10"}
                            margin={"0 auto"}
                            variant={"outline"}
                            borderColor={"brand.red"}
                            borderRadius={"full"}
                            className="each_plan_button"
                            fontFamily={"heading"}
                            fontWeight={"500"}
                            mt={4}
                            onClick={() =>
                              selectUpdatePlan(PlanIds.hack, "HACK", "DOWN")
                            }
                          >
                            Downgrade
                          </Button>
                        ) : (
                          <Button
                            size={"sm"}
                            width={"40"}
                            height={"10"}
                            margin={"0 auto"}
                            variant={"outline"}
                            borderColor={"brand.red"}
                            borderRadius={"full"}
                            className="each_plan_button"
                            fontFamily={"heading"}
                            fontWeight={"500"}
                            mt={4}
                            onClick={() =>
                              selectUpdatePlan(
                                "667d1ab8a5f239abc0f8db28",
                                "LAUNCH",
                                "UP"
                              )
                            }
                          >
                            Upgrade
                          </Button>
                        )}
                      </Stack>
                    </Stack>
                    <Stack
                      border={"2px solid"}
                      borderColor={
                        props.currentPlan === "LAUNCH"
                          ? "brand.red"
                          : "brand.lightGray"
                      }
                      boxShadow={
                        props.currentPlan === "LAUNCH"
                          ? "0px 2px 12px #ff000099"
                          : ""
                      }
                      py={8}
                      px={8}
                      spacing={8}
                      width="400px"
                      bg={"black"}
                      mt={6}
                      className="each_plan"
                    >
                      <Box>
                        <Image
                          src="/assets/plane.png"
                          alt="plane"
                          margin={"0 auto"}
                        />
                      </Box>
                      <Text
                        as={"span"}
                        textAlign={"center"}
                        fontSize={"18px"}
                        color={"brand.white"}
                      >
                        {launchPlanSpecs.name ?? "--"}
                      </Text>
                      <Flex justifyContent={"center"}>
                        <Box
                          px={4}
                          borderRight={"2px solid"}
                          borderColor={"brand.white"}
                        >
                          <Text
                            as={"div"}
                            fontSize={"4xl"}
                            fontWeight={"700"}
                            color={"brand.white"}
                            fontFamily={"heading"}
                          >
                            ${launchPlanSpecs.amount.usd ?? "199"}
                            {/* ${ "99"} */}
                          </Text>
                        </Box>
                        <Box
                          px={4}
                          fontSize={"1xl"}
                          fontWeight={"300"}
                          color={"brand.white"}
                          fontFamily={"heading"}
                        >
                          <Text as={"div"}>PER</Text>
                          <Text as={"div"}>MONTH</Text>
                        </Box>
                      </Flex>
                      <Stack spacing={4} margin={"0 auto"} ps={"20px"}>
                        <Stack direction={"row"}>
                          <Box>
                            <Image
                              src="/assets/Icons/vector-tick.svg"
                              alt="tick"
                            />
                          </Box>
                          <Text
                            as={"div"}
                            fontFamily={"Jost"}
                            fontSize={{ base: "14px", lg: "md" }}
                            color="brand.yellow"
                            fontWeight={"500"}
                          >
                            {formatCreditUsage(launchPlanSpecs.credits)} credits
                            every month
                          </Text>
                        </Stack>
                        <Stack direction={"row"}>
                          <Box>
                            <Image
                              src="/assets/Icons/vector-tick.svg"
                              alt="tick"
                            />
                          </Box>
                          <Text
                            as={"div"}
                            fontFamily={"Jost"}
                            fontSize={{ base: "14px", lg: "md" }}
                            color="brand.midGrey"
                            fontWeight={"500"}
                          >
                            {" "}
                            {/* {launchPlanSpecs.rateLimitPerSecond} API req/sec */}
                            30 API/GraphQL req/sec
                          </Text>
                        </Stack>
                        <Stack direction={"row"}>
                          <Box>
                            <Image
                              src="/assets/Icons/vector-tick.svg"
                              alt="tick"
                            />
                          </Box>
                          <Text
                            as={"div"}
                            fontFamily={"Jost"}
                            fontSize={{ base: "14px", lg: "md" }}
                            color="brand.midGrey"
                            fontWeight={"500"}
                          >
                            {" "}
                            {/* {launchPlanSpecs.rpcLimitPerSecond} RPC/DAS req/sec */}
                            150 RPC req/sec
                          </Text>
                        </Stack>
                        {launchPlanSpecs.isHardStopActivatedOnPlan ? (
                          <Stack direction={"row"}>
                            <Box>
                              <Image
                                src="/assets/Icons/vector-tick.svg"
                                alt="tick"
                              />
                            </Box>
                            <Text
                              as={"div"}
                              fontFamily={"Jost"}
                              fontSize={{ base: "14px", lg: "md" }}
                              color="brand.midGrey"
                              fontWeight={"500"}
                            >
                              {" "}
                              No Auto-Scaling (Capped at{" "}
                              {formatCreditUsage(
                                Number(launchPlanSpecs.credits)
                              )}
                              )
                            </Text>
                          </Stack>
                        ) : (
                          <Stack direction={"row"}>
                            <Box>
                              <Image
                                src="/assets/Icons/vector-tick.svg"
                                alt="tick"
                              />
                            </Box>
                            <Text
                              as={"div"}
                              fontFamily={"Jost"}
                              fontSize={{ base: "14px", lg: "md" }}
                              color="brand.midGrey"
                              fontWeight={"500"}
                            >
                              $
                              {/* {
                                launchPlanSpecs
                                  .perCreditChargeAfterExceedingUsage.usd
                              }{" "} */}
                              3.3 for each additional 1M credits
                            </Text>
                          </Stack>
                        )}
                        <Stack direction={"row"}>
                          <Box>
                            <Image
                              src="/assets/Icons/vector-tick.svg"
                              alt="tick"
                            />
                          </Box>
                          <Text
                            as={"div"}
                            fontFamily={"Jost"}
                            fontSize={{ base: "14px", lg: "md" }}
                            color="brand.midGrey"
                            fontWeight={"500"}
                          >
                            {" "}
                            Callbacks Enabled
                          </Text>
                        </Stack>
                      </Stack>
                      
                      <Stack>
                        {props.currentPlan === "FREE" ? (
                          <Button
                            size={"sm"}
                            width={"40"}
                            height={"10"}
                            margin={"0 auto"}
                            variant={"outline"}
                            borderColor={"brand.red"}
                            borderRadius={"full"}
                            className="each_plan_button"
                            fontFamily={"heading"}
                            fontWeight={"500"}
                            mt={4}
                            onClick={() =>
                              selectPlanAndPay("LAUNCH", PlanIds.launch)
                            }
                          >
                            Select Plan
                          </Button>
                        ) : props.currentPlan === "LAUNCH" ? (
                          <Button
                            size={"sm"}
                            width={"40"}
                            height={"10"}
                            margin={"0 auto"}
                            marginTop={4}
                            variant={"outline"}
                            borderColor={"brand.red"}
                            borderRadius={"full"}
                            className="each_plan_button"
                            bgColor={"brand.red"}
                            fontFamily={"heading"}
                            fontWeight={"500"}
                          >
                            Current Plan
                          </Button>
                        ) : PlanWeight[props.currentPlan] >
                          PlanWeight["LAUNCH"] ? (
                          <Button
                            size={"sm"}
                            width={"40"}
                            height={"10"}
                            margin={"0 auto"}
                            variant={"outline"}
                            borderColor={"brand.red"}
                            borderRadius={"full"}
                            className="each_plan_button"
                            fontFamily={"heading"}
                            fontWeight={"500"}
                            mt={4}
                            onClick={() =>
                              selectUpdatePlan(PlanIds.launch, "LAUNCH", "DOWN")
                            }
                          >
                            Downgrade
                          </Button>
                        ) : (
                          <Button
                            size={"sm"}
                            width={"40"}
                            height={"10"}
                            margin={"0 auto"}
                            variant={"outline"}
                            borderColor={"brand.red"}
                            borderRadius={"full"}
                            className="each_plan_button"
                            fontFamily={"heading"}
                            fontWeight={"500"}
                            mt={4}
                            onClick={() =>
                              selectUpdatePlan(PlanIds.launch, "LAUNCH", "UP")
                            }
                          >
                            Upgrade
                          </Button>
                        )}
                      </Stack>
                    </Stack>
                    <Stack
                      border={"2px solid"}
                      borderColor={
                        props.currentPlan === "SCALE"
                          ? "brand.red"
                          : "brand.lightGray"
                      }
                      boxShadow={
                        props.currentPlan === "SCALE"
                          ? "0px 2px 12px #ff000099"
                          : ""
                      }
                      bg={"black"}
                      py={8}
                      px={8}
                      spacing={8}
                      width="400px"
                      mt={6}
                      className="each_plan"
                    >
                      <Box>
                        <Image
                          src="/assets/rocket.png"
                          alt="plane"
                          margin={"0 auto"}
                        />
                      </Box>
                      <Text
                        as={"div"}
                        textAlign={"center"}
                        fontSize={"18px"}
                        color={"brand.white"}
                      >
                        {scalePlanSpecs.name ?? "--"}
                      </Text>
                      <Flex justifyContent={"center"}>
                        <Box
                          px={4}
                          borderRight={"2px solid"}
                          borderColor={"brand.white"}
                        >
                          <Text
                            as={"div"}
                            fontSize={"4xl"}
                            fontWeight={"700"}
                            color={"brand.white"}
                            fontFamily={"heading"}
                          >
                            {/* ${scalePlanSpecs.amount.usd ?? "279"} */}
                            $499
                          </Text>
                        </Box>
                        <Box
                          px={4}
                          fontSize={"1xl"}
                          fontWeight={"300"}
                          color={"brand.white"}
                          fontFamily={"heading"}
                        >
                          <Text as={"div"}>PER</Text>
                          <Text as={"div"}>MONTH</Text>
                        </Box>
                      </Flex>
                      <Stack spacing={4} margin={"0 auto"} ps={"20px"}>
                        <Stack direction={"row"}>
                          <Box>
                            <Image
                              src="/assets/Icons/vector-tick.svg"
                              alt="tick"
                            />
                          </Box>
                          <Text
                            as={"div"}
                            fontFamily={"Jost"}
                            fontSize={{ base: "14px", lg: "md" }}
                            color="brand.yellow"
                            fontWeight={"500"}
                          >
                            {" "}
                            {formatCreditUsage(scalePlanSpecs.credits)} credits
                            every month
                          </Text>
                        </Stack>
                        <Stack direction={"row"}>
                          <Box>
                            <Image
                              src="/assets/Icons/vector-tick.svg"
                              alt="tick"
                            />
                          </Box>
                          <Text
                            as={"div"}
                            fontFamily={"Jost"}
                            fontSize={{ base: "14px", lg: "md" }}
                            color="brand.midGrey"
                            fontWeight={"500"}
                          >
                            {" "}
                            150 API/GraphQL req/sec
                          </Text>
                        </Stack>
                        <Stack direction={"row"}>
                          <Box>
                            <Image
                              src="/assets/Icons/vector-tick.svg"
                              alt="tick"
                            />
                          </Box>
                          <Text
                            as={"div"}
                            fontFamily={"Jost"}
                            fontSize={{ base: "14px", lg: "md" }}
                            color="brand.midGrey"
                            fontWeight={"500"}
                          >
                            {" "}
                            {/* {scalePlanSpecs.rpcLimitPerSecond} RPC/DAS req/sec */}
                            400 RPC req/sec
                          </Text>
                        </Stack>
                        {scalePlanSpecs.isHardStopActivatedOnPlan ? (
                          <Stack direction={"row"}>
                            <Box>
                              <Image
                                src="/assets/Icons/vector-tick.svg"
                                alt="tick"
                              />
                            </Box>
                            <Text
                              as={"div"}
                              fontFamily={"Jost"}
                              fontSize={{ base: "14px", lg: "md" }}
                              color="brand.midGrey"
                              fontWeight={"500"}
                            >
                              {" "}
                              No Auto-Scaling (Capped at{" "}
                              {formatCreditUsage(
                                Number(scalePlanSpecs.credits)
                              )}
                              )
                            </Text>
                          </Stack>
                        ) : (
                          <Stack direction={"row"}>
                            <Box>
                              <Image
                                src="/assets/Icons/vector-tick.svg"
                                alt="tick"
                              />
                            </Box>
                            <Text
                              as={"div"}
                              fontFamily={"Jost"}
                              fontSize={{ base: "14px", lg: "md" }}
                              color="brand.midGrey"
                              fontWeight={"500"}
                            >
                              $1.65
                              {/* {
                                scalePlanSpecs
                                  .perCreditChargeAfterExceedingUsage.usd
                              }{" "} */}{" "}
                              for each additional 1M credits
                            </Text>
                          </Stack>
                        )}
                        <Stack direction={"row"}>
                          <Box>
                            <Image
                              src="/assets/Icons/vector-tick.svg"
                              alt="tick"
                            />
                          </Box>
                          <Text
                            as={"div"}
                            fontFamily={"Jost"}
                            fontSize={{ base: "14px", lg: "md" }}
                            color="brand.midGrey"
                            fontWeight={"500"}
                          >
                            {" "}
                            Callbacks Enabled
                          </Text>
                        </Stack>
                        <Stack direction={"row"}>
                          <Box>
                            <Image
                              src="/assets/Icons/vector-tick.svg"
                              alt="tick"
                            />
                          </Box>
                          <Text
                            as={"div"}
                            fontFamily={"Jost"}
                            fontSize={{ base: "14px", lg: "md" }}
                            color="brand.midGrey"
                            fontWeight={"500"}
                          >
                            {" "}
                            Dedicated Discord/Telegram Channel
                          </Text>
                        </Stack>
                      </Stack>
                      <Stack>
                        {props.currentPlan === "FREE" ? (
                          <Button
                            size={"sm"}
                            width={"40"}
                            height={"10"}
                            margin={"0 auto"}
                            variant={"outline"}
                            borderColor={"brand.red"}
                            borderRadius={"full"}
                            className="each_plan_button"
                            fontFamily={"heading"}
                            fontWeight={"500"}
                            mt={4}
                            onClick={() =>
                              selectPlanAndPay("SCALE", PlanIds.scale)
                            }
                          >
                            Select Plan
                          </Button>
                        ) : props.currentPlan === "SCALE" ? (
                          <Button
                            size={"sm"}
                            width={"40"}
                            height={"10"}
                            margin={"0 auto"}
                            marginTop={4}
                            variant={"outline"}
                            borderColor={"brand.red"}
                            borderRadius={"full"}
                            className="each_plan_button"
                            fontFamily={"heading"}
                            bgColor={"brand.red"}
                            fontWeight={"500"}
                          >
                            Current Plan
                          </Button>
                        ) : PlanWeight[props.currentPlan] >
                          PlanWeight["SCALE"] ? (
                          <Button
                            size={"sm"}
                            width={"40"}
                            height={"10"}
                            margin={"0 auto"}
                            variant={"outline"}
                            borderColor={"brand.red"}
                            borderRadius={"full"}
                            className="each_plan_button"
                            fontFamily={"heading"}
                            fontWeight={"500"}
                            mt={4}
                            onClick={() =>
                              selectUpdatePlan(PlanIds.scale, "SCALE", "DOWN")
                            }
                          >
                            Downgrade
                          </Button>
                        ) : (
                          <Button
                            size={"sm"}
                            width={"40"}
                            height={"10"}
                            margin={"0 auto"}
                            variant={"outline"}
                            borderColor={"brand.red"}
                            borderRadius={"full"}
                            className="each_plan_button"
                            fontFamily={"heading"}
                            fontWeight={"500"}
                            mt={4}
                            onClick={() =>
                              selectUpdatePlan(PlanIds.scale, "SCALE", "UP")
                            }
                          >
                            Upgrade
                          </Button>
                        )}
                      </Stack>
                    </Stack>
                  </Flex>
                  
                  {/* <Box
                    as="div"
                    border={"2px solid"}
                    borderColor={
                      props.currentPlan === "ENTERPRISE"
                        ? "brand.red"
                        : "brand.lightGray"
                    }
                    boxShadow={
                      props.currentPlan === "ENTERPRISE"
                        ? "0px 2px 12px #ff000099"
                        : ""
                    }
                    borderRadius={"3xl"}
                    py={8}
                    px={8}
                    bg={"black"}
                    mt={6}
                  >
                    <Stack wrap={"wrap"} justifyContent={"space-between"}>
                      <Heading
                        as="h3"
                        color={"brand.white"}
                        fontWeight={"600"}
                        fontSize={"3xl"}
                        mb={0}
                      >
                        Enterprise{" "}
                      </Heading>
                    </Stack>
                    <Stack mt={2}>
                      <Text
                        as={"div"}
                        fontWeight={500}
                        color={"brand.grey"}
                        fontSize={{ base: "14px", lg: "md" }}
                      >
                        For business with larger volume or unique business models.
                        <br />
                        We provide custom plans based on your credit and rate limit requirements. Reach out to us on <Text as={"a"} href="https://discord.gg/8JyZCjRPmr" target="_blank" color={"brand.yellow"} _hover={{ color: "brand.red" }}>discord</Text> to discuss.
                      </Text>
                    </Stack>
                    <Stack mt={6} w={"160px"}>
                      <PopupButton
                        url="https://calendly.com/shyft-to"
                        rootElement={document.getElementsByTagName("body")[0]}
                        text="Contact Us"
                        className={"calendly_button_red_outline"}
                      />
                    </Stack>
                  </Box>
                  <Box
                    
                  >
                    <Box
                        as="div"
                        border={'2px solid'}
                        borderColor="brand.lightGray"
                        borderRadius={'3xl'}
                        bgColor={'#000'}
                        py={8}
                        px={8}
                        
                        mt={6}
                        
                    >
                        <Stack wrap={'wrap'} justifyContent={'space-between'}>
                            <Heading as="h3" color={'brand.white'} fontWeight={'600'} fontSize={'3xl'}>
                                Dedicated Solana RPCs{' '}
                                
                            </Heading>
                            
                        </Stack>
                        <Stack mt={2}>
                            <Text color={"brand.grey"} fontSize={{ base: '14px', lg: 'md' }} fontWeight={500}>Boost your development with our fault-tolerant dedicated nodes. Starting from $1800/mo. We also deploy a proxy which tracks your node's health and routes to our backup pool in case of lag or downtimes.</Text>
                        </Stack>
                        <Stack mt={6}>
                        <Button
                            as={"a"}
                            href="https://discord.gg/8JyZCjRPmr"
                            target="_blank"
                            pt={"10px"}
                            pb={"10px"}
                            display={"block"}
                            size={"md"}
                            // width={"28"}
                            height={"10"}
                            maxW={"170px"}
                            border={"1px solid"}
                            borderColor={'brand.red'}
                            bgColor={'brand.black'}
                            color={'brand.white'}
                            borderRadius={"full"}
                            textColor={"#fff"}
                            fontFamily={"heading"}
                            fontWeight={"400"}
                            _hover={{
                                bgColor: 'brand.red',
                                color: 'brand.white',
                                textDecoration: 'none',
                            }}
                            mt={{base: 5, md: 1, lg: 0}}
                            textAlign={"center"}
                        >
                            Connect with us
                        </Button>
                        </Stack>
                    </Box>
                  </Box> */}
                  <Box>
                    <Flex as="div" pt={{ base: 2, md: 2 }} flexWrap={"wrap"} justifyContent={{sm: "space-around", md: "space-between"}}>
                        <Stack
                            initial={{ opacity: 0, y: 10 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            viewport={{ once: true }}
                            transition={{ duration: 0.3,delay: 0.1 }}
                            maxW={{base: "100%", md:"49%"}}
                        >
                            <Box
                                as="div"
                                border={'2px solid'}
                                borderColor="brand.lightGray"
                                borderRadius={'3xl'}
                                py={8}
                                px={8}
                                bg={"black"}
                                mt={6}
                            >
                                <Stack wrap={'wrap'} justifyContent={'space-between'}>
                                    <Heading as="h3" color={'brand.white'} fontWeight={'600'} fontSize={'3xl'}>
                                        Shared gRPC Network{' '}
                                        
                                    </Heading>
                                    
                                </Stack>
                                <Stack mt={2}>
                                    <Text color={"brand.grey"} fontSize={{ base: '14px', lg: 'md' }} fontWeight={500}>No credits, no metering, only unlimited streaming. Select the region closest to you and start instantly at <Text as={"span"} color={"brand.yellow"}>$199/mo/ip</Text>. Each region is powered by multiple nodes for fault tolerance. 
                                        {/* Reach out to us on <Text as={"a"} href="https://discord.gg/8JyZCjRPmr" target="_blank" color={"brand.yellow"} _hover={{ color: "brand.red" }}>discord</Text> for queries. */}
                                    </Text>
                                </Stack>
                                <Stack mt={{base: 0, md:6}}>
                                    {/* <PopupButton
                                        url="https://calendly.com/shyft-to"
                                        rootElement={document.getElementsByTagName("body")[0]}
                                        text=""
                                        className={"calendly_button_common"}
                                    /> */}
                                    <Link to="/dashboard/grpc">
                                      <Box
                                        size={"sm"}
                                        fontSize={"sm"}
                                        fontWeight={"600"}
                                        fontFamily={"heading"}
                                        borderRadius={"full"}
                                        px={{ base: "12px", xl: "24px" }}
                                        py={{ base: "6px", lg: "8px" }}
                                        bg={"transparent"}
                                        border="1px solid"
                                        borderColor="brand.red"
                                        color={"brand.white"}
                                        my={"-2"}
                                        _hover={{
                                          bgColor: 'brand.red',
                                          color: 'brand.white',
                                          textDecoration: 'none',
                                      }}
                                        textAlign={"center"}
                                        height={"10"}
                                        maxW={"170px"}
                                      >
                                        Upgrade Plan
                                      </Box>
                                    </Link>
            
                                    {/* <ExtLink
                                        as={"a"}
                                        href={'/dashboard/pricing'}
                                        _hover={{
                                            textDecoration: "none"
                                        }}
                                    >
                                        <Box
                                            size={'sm'}
                                            width={'40'}
                                            height={'10'}
                                            // margin={'0 auto'}
                                            border={"1px solid"}
                                            borderColor={'brand.red'}
                                            borderRadius={'full'}
                                            className="each_plan_button"
                                            fontFamily={'heading'}
                                            fontWeight={"500"}
                                            fontSize={{base: "sm", md: "sm"}}
                                            lineHeight={"38px"}
                                            textAlign={"center"}
                                            _hover={{
                                                textDecoration: "none",
                                                bgColor: 'brand.red',
                                            }}
                                        >
                                            Get Started
                                        </Box>
                                    </ExtLink> */}
                                </Stack>
                            </Box>
                        </Stack>
                        <Stack
                            initial={{ opacity: 0, y: 10 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            viewport={{ once: true }}
                            transition={{ duration: 0.3,delay: 0.2 }}
                            maxW={{base: "100%", md:"49%"}}
                            id="jup-swap-v6-api"
                        >
                            <Box
                                as="div"
                                border={'2px solid'}
                                borderColor="brand.lightGray"
                                borderRadius={'3xl'}
                                py={8}
                                px={8}
                                pb={6}
                                bg={"black"}
                                mt={8}                                
                            >
                                <Stack wrap={'wrap'} justifyContent={'space-between'}>
                                    <Heading as="h3" color={'brand.white'} fontWeight={'600'} fontSize={'3xl'}>
                                        JUP Swap API 🔥{' '}
                                        
                                    </Heading>
                                    
                                </Stack>
                                <Stack mt={2}>
                                    <Text color={"brand.grey"} fontSize={{ base: '14px', lg: 'md' }} fontWeight={500}>Access region specific JUP Swap API. No self-hosting required, start trading instantly at <Text as={"span"} color={"brand.yellow"}>$299/mo</Text> and <Text as={"span"} color={"brand.yellow"}>150 req/sec</Text>. No credit system or upper monthly limit. Reach out to us on <Text as={"a"} href="#" target="_blank" color={"brand.yellow"} _hover={{ color: "brand.red" }}>dashboard support</Text> to get access.</Text>
                                </Stack>
                                <Stack mt={{base: 0, md: 2}}>
                                <Button
                                    as={"a"}
                                    href={linksConfig.DISCORD_LINK}
                                    target="_blank"
                                    pt={"11px"}
                                    pb={"10px"}
                                    display={"block"}
                                    size={"sm"}
                                    // width={"28"}
                                    height={"10"}
                                    maxW={"170px"}
                                    border={"1px solid"}
                                    borderColor={'brand.red'}
                                    bgColor={'#000'}
                                    color={'brand.white'}
                                    borderRadius={"full"}
                                    textColor={"#fff"}
                                    fontFamily={"heading"}
                                    fontWeight={"500"}
                                    _hover={{
                                        bgColor: 'brand.red',
                                        color: 'brand.white',
                                        textDecoration: 'none',
                                    }}
                                    mt={{base: 3, md: 2, lg: 0}}
                                    textAlign={"center"}
                                >
                                    Connect with us
                                </Button>
                                </Stack>
                            </Box>
                        </Stack>
                    </Flex>
                </Box>
                <Box>
                    <Flex as="div" pt={{ base: 2, md: 2 }} flexWrap={"wrap"} justifyContent={{sm: "space-around", md: "space-between"}}>
                    <Stack
                            maxW={{base: "100%", md:"49%"}}
                        >
                            <Box
                                as="div"
                                border={'2px solid'}
                                borderColor="brand.lightGray"
                                borderRadius={'3xl'}
                                py={8}
                                px={8}
                                bg={"black"}
                                mt={6}
                            >
                                <Stack wrap={'wrap'} justifyContent={'space-between'}>
                                    <Heading as="h3" color={'brand.white'} fontWeight={'600'} fontSize={'3xl'}>
                                        Enterprise{' '}
                                        
                                    </Heading>
                                    
                                </Stack>
                                <Stack mt={2}>
                                    <Text color={"brand.grey"} fontSize={{ base: '14px', lg: 'md' }} fontWeight={500}>For business with larger volume or unique business models. We provide custom plans based on your credit and rate limit requirements.
                                        <br />Reach out to us on <Text as={"a"} color={"brand.yellow"} _hover={{ color: "brand.red" }}>dashboard support</Text> for queries.
                                    </Text>
                                </Stack>
                                <Stack mt={{base: 3, md:6}}>
                                    <PopupButton
                                        url="https://calendly.com/shyft-to"
                                        rootElement={document.getElementsByTagName("body")[0]}
                                        text=""
                                        className={"calendly_button_common"}
                                    />
                                    {/* <Button
                                        as={"a"}
                                        href="https://discord.gg/8JyZCjRPmr"
                                        target="_blank"
                                        pt={"11px"}
                                        pb={"12px"}
                                        display={"block"}
                                        size={"sm"}
                                        // width={"28"}
                                        height={"10"}
                                        maxW={"170px"}
                                        border={"1px solid"}
                                        borderColor={'brand.red'}
                                        bgColor={'brand.black'}
                                        color={'brand.white'}
                                        borderRadius={"full"}
                                        textColor={"#fff"}
                                        fontFamily={"heading"}
                                        fontWeight={"600"}
                                        _hover={{
                                            bgColor: 'brand.red',
                                            color: 'brand.white',
                                            textDecoration: 'none',
                                        }}
                                        mt={{base: 5, md: 1, lg: 1}}
                                        textAlign={"center"}
                                    >
                                        Connect with us
                                    </Button> */}
                                </Stack>
                            </Box>
                        </Stack>
                        <Stack
                            maxW={{base: "100%", md:"49%"}}
                        >
                            <Box
                                as="div"
                                border={'2px solid'}
                                borderColor="brand.lightGray"
                                borderRadius={'3xl'}
                                py={8}
                                px={8}
                                bg={"black"}
                                mt={6}
                            >
                                <Stack wrap={'wrap'} justifyContent={'space-between'}>
                                    <Heading as="h3" color={'brand.white'} fontWeight={'600'} fontSize={'3xl'}>
                                        Dedicated Solana RPCs{' '}
                                        
                                    </Heading>
                                    
                                </Stack>
                                <Stack mt={2}>
                                    <Text color={"brand.grey"} fontSize={{ base: '14px', lg: 'md' }} fontWeight={500}>Boost your development with our fault-tolerant dedicated nodes. Starting from <Text as={"span"} color={"brand.yellow"}>$1800/mo</Text>. We also deploy a proxy which tracks your node's health and routes to our backup pool in case of lag or downtimes.</Text>
                                </Stack>
                                <Stack mt={{base: 0, md:6}}>
                                    <Button
                                        as={"a"}
                                        href={linksConfig.DISCORD_LINK}
                                        target="_blank"
                                        pt={"12px"}
                                        pb={"10px"}
                                        display={"block"}
                                        size={"sm"}
                                        // width={"28"}
                                        height={"10"}
                                        maxW={"170px"}
                                        border={"1px solid"}
                                        borderColor={'brand.red'}
                                        bgColor={'#000'}
                                        color={'brand.white'}
                                        borderRadius={"full"}
                                        textColor={"#fff"}
                                        fontFamily={"heading"}
                                        fontWeight={"500"}
                                        _hover={{
                                            bgColor: 'brand.red',
                                            color: 'brand.white',
                                            textDecoration: 'none',
                                        }}
                                        mt={{base: 5, md: 1, lg: 0}}
                                        textAlign={"center"}
                                    >
                                        Connect with us
                                    </Button>
                                </Stack>
                            </Box>
                        </Stack>
                    </Flex>
                </Box>
                </Stack>
              </Box>
            </Container>
          )}
        </div>
      </div>
    </div>
  );
};

export default PricingPage;