import { useState, useEffect } from "react";
import axios from "axios";

import MainStyles from "../../resources/css/css.module.css";
import GreyTicks from "../../resources/images/grey-ticks.svg";

const HomepagePricing = () => {
    const credits = [0,10000,20000,40000,50000,200000,500000,800000,1000000,2000000,3000000,4000000,5000000,6000000,7500000,9000000,10000000,20000000,30000000,40000000,-1];
    const credits_display = ['0','10K','20K','40K','50K','200K','500K','800K','1M','2M','3M','4M','5M','6M','7.5M','9M','10M','20M','30M','40M','Ꝏ'];
    const avgCallDiv = 15;
    const [rangeValue, setRangeValue] = useState(0);
    const [displayValue,setDisplayValue] = useState('0');
    const [perCredit, setPerCredit] = useState(0);
    const [finalCost,setFinalCost] = useState(0);
    const [currentCredCons, setCredCons] = useState(0);
    const [isLoaded, setLoaded] = useState(false);
    const [errOcc, setErrOcc] = useState(false);
    const [data, setData] = useState(null);

    useEffect(() => {
        const endPoint = process.env.REACT_APP_AUTH_EP;
        axios({
            url: `${endPoint}pricing`,
            method: "GET",
        })
            .then((res) => {
                if (res.data.success === true) {
                    setData(res.data);
                }
                else {
                    setErrOcc(true);
                }
            })
            // Catch errors if any
            .catch((err) => {
                console.warn(err);
                setErrOcc(true);
            });
    }, []);

    useEffect(() => {
        if (data !== null)
            setLoaded(true);
    }, [data]);

    useEffect(() => {
        if(data!== null)
        {
            if(currentCredCons>=0 && currentCredCons<=50000)
            {
                setPerCredit(data.result.pricing_slabs[0]);
            }
            else if(currentCredCons>50000 && currentCredCons<=1000000)
            {
                setPerCredit(data.result.pricing_slabs[50000]);
            }
            else if(currentCredCons>1000000 && currentCredCons<=5000000)
            {
                setPerCredit(data.result.pricing_slabs[1000000]);
            }
            else if(currentCredCons>5000000 && currentCredCons<=10000000)
            {
                setPerCredit(data.result.pricing_slabs[5000000]);
            }
            else if(currentCredCons>10000000)
            {
                setPerCredit(data.result.pricing_slabs[10000000]); 
            }
        }
        
    
    }, [currentCredCons]);

    useEffect(() => {
        if(data!==null)
        {
            if(currentCredCons>=0 && currentCredCons<=50000)
            {
                setFinalCost(perCredit*currentCredCons);
            }
            else if(currentCredCons>50000 && currentCredCons<=1000000)
            {
                setFinalCost((currentCredCons-50000)*perCredit);
            }
            else if(currentCredCons>1000000 && currentCredCons<=5000000)
            {
                setFinalCost(0+(950000*data.result.pricing_slabs[50000])+((currentCredCons-1000000)*perCredit));
            }
            else if(currentCredCons>5000000 && currentCredCons<=10000000)
            {
                setFinalCost(0+(950000*data.result.pricing_slabs[50000])+(4000000*data.result.pricing_slabs[1000000])+((currentCredCons-5000000)*perCredit));
            }
            else if(currentCredCons>10000000)
            {
                setFinalCost(0+(950000*data.result.pricing_slabs[50000])+(4000000*data.result.pricing_slabs[1000000])+(5000000*data.result.pricing_slabs[5000000])+((currentCredCons-10000000)*perCredit));
            }
        }
        
      
    }, [perCredit,currentCredCons])
    
    

    const showToggle = (showing) => {
        if(showing)
        {

            document.getElementById("showCreditsValue").style.opacity=0.0;
        }
        else
        {
            document.getElementById("showCreditsValue").style.opacity=1.0;
        }
    }

    return (
        <div>
            {(isLoaded) &&
                <div>
                    <div className="container-lg">
                        <div className={`row ${MainStyles.pricing_heading_container}`}>
                            <div className="col-12">
                                <h6 className={MainStyles.small_keywords_2}>Pay As You Go</h6>
                                <h2 className={MainStyles.main_headings_sub}>
                                    Pricing That Makes Sense
                                </h2>
                                <p className={MainStyles.p_para_1}>
                                    Power your Solana projects with simple, transparent and variable pricing.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="container-lg">
                        <div className={MainStyles.pricing_gradient_outer}>
                            <div className={MainStyles.pricing_gradient_inner}>
                                <div className="row">
                                    <div className={`col-md-12 col-lg-9 ${MainStyles.meter_border}`}>
                                        <div className={MainStyles.meter_container}>
                                            <h4 className={`text-center ${MainStyles.thick_text}`}>
                                                Free 50k credits at 50 req/sec every month.
                                            </h4>
                                            <div className={`text-center ${MainStyles.xsmallest_text}`}>Cost per credit</div>
                                            <h4 className={`text-center ${MainStyles.gradient_text_small}`}>
                                                {(perCredit === 0) ? "Free" : ("$ " + perCredit)}
                                            </h4>
                                        </div>
                                        <div className={MainStyles.meter_container_master}>
                                            <div className={MainStyles.only_meter}>
                                                <div className={MainStyles.bubble} id="showCreditsValue"><div>{displayValue}</div></div>
                                                <input className={MainStyles.range} id="bubbleShower" type="range" value={rangeValue} onChange={(e) => {
                                                    const val = e.target.value;
                                                    const min = e.target.min ? e.target.min : 0;
                                                    const max = e.target.max ? e.target.max : credits.length;
                                                    const newVal = Number(((val - min) * 100) / (max - min));
                                                    setRangeValue(val);
                                                    document.getElementById("showCreditsValue").style.left = `calc(${newVal}% + (${8 - newVal * 0.15}px))`;
                                                    setCredCons(credits[e.target.value]);
                                                    setDisplayValue(credits_display[e.target.value]);
                                                }}
                                                onMouseDown={() => showToggle(false)}
                                                onMouseUp={() => showToggle(true)}
                                                onTouchStart={() => showToggle(false)}
                                                onTouchEnd={() => showToggle(true)}
                                                min={0} max={credits.length-1} />
                                                <div className={`d-flex justify-content-between ${MainStyles.marker_text}`}>
                                                    <div>0</div>
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                    <div>50K</div>
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                    <div>1M</div>
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                    <div>5M</div>
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                    <div>10M</div>
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                    <div>Ꝏ</div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                    <div className="col-md-12 col-lg-3">
                                        <div className={MainStyles.meter_container_estimated}>
                                            <h4 className={`text-center ${MainStyles.thin_text}`}>
                                                Approx API Calls
                                            </h4>
                                            <h3 className={`text-center ${MainStyles.gradient_text_xlarge}`}>
                                                {(currentCredCons===-1)?"Ꝏ":(
                                                    ((currentCredCons/avgCallDiv)>999999)?((currentCredCons/(avgCallDiv*1000000)).toFixed(0)+'M'):
                                                    ((currentCredCons/avgCallDiv)>999)?((currentCredCons/(avgCallDiv*1000)).toFixed(0)+'K'):(currentCredCons/avgCallDiv).toFixed(0)
                                                )}
                                            </h3>
                                        </div>
                                        <div className={MainStyles.meter_container_estimated}>
                                            <h4 className={`text-center ${MainStyles.thin_text}`}>
                                                Estimated Total Cost
                                            </h4>
                                            <h3 className={`text-center ${MainStyles.gradient_text_xlarge}`}>
                                                {/* {(currentCredCons > 10000000) ? "Custom" : (`$ ${((currentCredCons - data.result.free_credits) * perCredit).toFixed(2)}`)} */}
                                                {(currentCredCons>=0)?(`$ ${finalCost}`):"Custom"}
                                            </h3>
                                        </div>
                                    </div>
                                </div>


                                <hr className={MainStyles.divider_hr} />
                                <div className={MainStyles.pay_details}>
                                    <div className="row">
                                        <div className="col">
                                            <h4 className={`${MainStyles.thin_text_1}`}>
                                                Continue for free at 5 req/sec after your free credits or pay as you go. With 100k credits you can
                                            </h4>
                                            <div className={`d-flex flex-wrap ${MainStyles.flex_capsules}`}>
                                                <div className={MainStyles.flex_capsule_pill}>
                                                    2k NFT Reads
                                                </div>
                                                <div className={MainStyles.flex_capsule_pill}>
                                                    300 NFT Writes
                                                </div>
                                                <div className={MainStyles.flex_capsule_pill}>
                                                    500 Token Mints
                                                </div>
                                                <div className={MainStyles.flex_capsule_pill}>
                                                    300 Tx History Calls
                                                </div>
                                                <div className={MainStyles.flex_capsule_pill}>
                                                    200 Marketplace Listings
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className={MainStyles.pay_compare}>
                                    <div className="row">
                                        <div className="col-md-12 col-lg-6">
                                            <h5 className={`text-center text-lg-start mb-2 mb-lg-0 ${MainStyles.main_title}`}>
                                                Free
                                            </h5>
                                            <div className={MainStyles.em_points}>
                                                <div className="d-flex">
                                                    <div className={MainStyles.em_icon_container}><img src={GreyTicks} alt="Grey Tick" /></div>
                                                    <div className={MainStyles.em_text_container}>Free 50k credits at 50 req/sec every month.</div>
                                                </div>
                                            </div>
                                            <div className={MainStyles.em_points}>
                                                <div className="d-flex">
                                                    <div className={MainStyles.em_icon_container}><img src={GreyTicks} alt="Grey Tick" /></div>
                                                    <div className={MainStyles.em_text_container}>Continue for free at 5 req/sec forever.</div>
                                                </div>
                                            </div>
                                            <div className="text-center text-lg-start">
                                                <a href="/get-api-key"className={MainStyles.em_btn}>Get Started</a>
                                            </div>

                                        </div>
                                        <div className="col-md-12 col-lg-6 pt-5 pt-lg-0">
                                            <h5 className={`text-center text-lg-start mb-3 mb-lg-0 ${MainStyles.main_title}`}>
                                                Pay as you go
                                            </h5>
                                            <div className={MainStyles.em_points}>
                                                <div className="d-flex">
                                                    <div className={MainStyles.em_icon_container}><img src={GreyTicks} alt="Grey Tick" /></div>
                                                    <div className={MainStyles.em_text_container}>Only pay for the APIs used above 50k credits.</div>
                                                </div>
                                            </div>
                                            <div className={MainStyles.em_points}>
                                                <div className="d-flex">
                                                    <div className={MainStyles.em_icon_container}><img src={GreyTicks} alt="Grey Tick" /></div>
                                                    <div className={MainStyles.em_text_container}>50 requests/second</div>
                                                </div>
                                            </div>
                                            <div className="text-center text-lg-start">
                                                <a href="/get-api-key" className={MainStyles.em_btn}>Get Started</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={MainStyles.enterprise_plans}>
                            <h2 className={MainStyles.dashboard_main_title}>Enterprise</h2>
                            <h3 className={MainStyles.dashboard_sub_title}>For business with larger volume or unique business models.</h3>
                            <h3 className={MainStyles.dashboard_sub_title}>Includes everything under pay as you go model plus enterprise rates on credits used.</h3>
                            <div className="pt-4">
                                <a className={MainStyles.btn_solid_grad} href="mailto:support@shyft.to">Contact Us</a>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

export default HomepagePricing;