import { useEffect, useState } from "react";
import {
  Box,
  Text,
  Flex,
  Container,
  Button,
} from "@chakra-ui/react";
import { FaCopy, FaCheckSquare, FaTrashAlt, FaCheck, FaEdit, FaWindowClose, FaWalking, FaCheckCircle } from "react-icons/fa";
import { MdCancel } from "react-icons/md";
// import { BeatLoader } from 'react-spinners';
import axios from "axios";
import { ReactSession } from "react-client-session";
// import { PopupButton } from "react-calendly";
// import GrpcMap from "./GrpcMap";
// import { ExternalLinkIcon } from '@chakra-ui/icons';

import LinkDarkCards from "./LinkDarkCards";
import JupMap from "./JupMap";
import { linksConfig } from "../../utils/CommonLinks";

const JupApi = (props) => {

  const [data, setData] = useState(null);
  const [copied, setCopied] = useState(false);
  const [errOcc, setErrOcc] = useState(false);


  useEffect(() => {
    const token = ReactSession.get("xTkn") ?? "";

    if (token !== "") {
      const endPoint = process.env.REACT_APP_AUTH_EP;
      axios({
        url: `${endPoint}dashboard/get_active_plans`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "ngrok-skip-browser-warning": "69420",
        },
      })
        .then((res) => {
          if (res.data.success === true) {
            
            const jupPlan = res.data.result.find(plan => plan.plan_name === "JUPITER");
            //console.log("jupPlan", jupPlan);
            if(jupPlan !== undefined && jupPlan?.plan_name === "JUPITER") setData(jupPlan);
            else setData([]);
            
          } else {
            setErrOcc(true);
          }
        })
        // Catch errors if any
        .catch((err) => {
          console.warn(err);
          setErrOcc(true);
          props.setLoading(false);
        });
    } else {
      //navigate("/get-api-key");
    }
  }, []);



  useEffect(() => {
    // setTimeout(() => {
    //   props.setLoading(false);
    // },800)
    if (data !== null || errOcc === true) props.setLoading(false);
  }, [data, errOcc]);



  function validateIPaddress(ipaddress) {
    if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ipaddress)) {
      return (true)
    }
    return (false)
  }
  return (
    <div>
      
      <Container maxW={"8xl"}>
        <Box
          as="div"
          // px={{ base: '16px', md: '24px', xl: '32px' }}
          // py={{ base: '16px', md: '24px' }}
          borderRadius={"14px"}
        >
          <Box>
            <Text
              as={"div"}
              fontFamily={"heading"}
              color={"brand.white"}
              fontSize={"2xl"}
              lineHeight={"3xl"}
              fontWeight={"600"}
              position={"relative"}
            >
              JUP Swap API

            </Text>

            <Text
              as={"div"}
              fontSize={{ base: "14px", lg: "md" }}
              fontWeight={"400"}
              color={"brand.lighterWhite"}
              pt={{ base: "4px", md: "6px" }}
            >
              Select your closest region and start trading instantly. No self-hosting and no credit system.
            </Text>
          </Box>
        </Box>
        
        {(data === null || (Array.isArray(data) && data.length === 0)) ? <Box pt={8} pb={8}>
        {/* {(data === null) ? <Box pt={8} pb={8}> */}
          <Box
            border={"2px solid"}
            borderColor={"brand.lightGray"}
            borderRadius={"14px"}
            _hover={{
              borderColor: "brand.red"
            }}
            backgroundColor={"black"}
            p={4}
            pb={4}
            ps={6}
          >
            <Flex flexDirection={{ base: "column", md: "row" }} justifyContent={{ base: "center", md: "space-between" }} alignItems={{ base: "initial", md: "center", xl: "start" }}>
              <Box flex='1'>
                <Flex>
                    <Box as="div" pt={"6px"} pe={"4px"}>
                        <MdCancel color="#E33535" size={"20px"}/>
                    </Box>
                    <Box as="div">
                        <Text
                            as={"div"}
                            fontFamily={"heading"}
                            color={"brand.white"}
                            fontSize={"xl"}
                            lineHeight={"2xl"}
                            fontWeight={"600"}
                            position={"relative"}
                            pb={"10px"}
                        >
                            JUP Access: Disabled
                        </Text>
                    </Box>
                </Flex>
                
                <Text as={"div"} color={"brand.lighterWhite"} fontSize={{ base: "14px", lg: "md" }}>
                  Access region specific JUP Swap API. No self-hosting required, start trading instantly at <Text as={"span"} color={"brand.yellow"}>$299/mo</Text> and <Text as={"span"} color={"brand.yellow"}>150 req/sec</Text>. No credit system or upper monthly limit.
                </Text>
                <Text as={"div"} color={"brand.yellow"} pt={"10px"} fontWeight={500} fontSize={{ base: "14px", lg: "md" }}>
                  Reach out to us on <Text as={"span"} color={"brand.yellow"} _hover={{ color: "brand.red" }}>dashboard support</Text> for access.
                </Text>
              </Box>
              <Box width={{ base: "20px", md: "48px" }} h={{ base: "20px", md: "40px" }}></Box>
              <Box ps={{ base: '0px', md: '18px' }} pe={{ base: '10px', md: '18px' }} textAlign={"center"}>
                <Flex flexDirection={{ base: "row", md: "column" }} justifyContent={{ base: "space-between", md: "flex-start" }}>
                  <Box>
                    <Text
                      as={"span"}
                      fontSize={"32px"}
                      lineHeight={{ base: "45px", md: "34px" }}
                      fontWeight={"700"}
                      color={"brand.white"}
                      fontFamily={"heading"}
                    >
                      $299
                    </Text>
                    <Box>
                      <Text
                        as={"div"}
                        fontSize={"13px"}
                        fontWeight={"300"}
                        color={"brand.lighterWhite"}
                        fontFamily={"heading"}
                        mt={"-4px"}
                        pb={2}
                      >
                        PER MONTH
                      </Text>
                    </Box>
                  </Box>
                  <Box>
                    <Button
                      as={"a"}
                      href={linksConfig.DISCORD_LINK}
                      target="_blank"
                      py={"8px"}
                      display={"block"}
                      size={"xs"}
                      width={"28"}
                      height={"32px"}
                      margin={"0 auto"}
                      bgColor={'brand.red'}
                      color={'brand.white'}
                      borderRadius={"full"}
                      textColor={"#fff"}
                      fontFamily={"heading"}
                      fontWeight={"400"}
                      _hover={{
                        bgColor: 'brand.white',
                        color: 'brand.red',
                        textDecoration: 'none',
                      }}
                      mt={{ base: 4, md: 1, lg: 0.5 }}
                    //   onClick={selectPlanAndPay}
                    >
                      Get Access
                    </Button>
                  </Box>
                </Flex>


              </Box>
            </Flex>
          </Box>
        </Box> :
          <Box pt={8} pb={8}>
          <Box
            border={"2px solid"}
            borderColor={"brand.lightGray"}
            borderRadius={"14px"}
            _hover={{
              borderColor: "brand.red"
            }}
            backgroundColor={"black"}
            p={4}
            pb={2}
            ps={6}
          >
            <Flex flexDirection={{ base: "column", md: "row" }} justifyContent={{ base: "center", md: "space-between" }} alignItems={{ base: "initial", md: "center", xl: "start" }}>
              <Box flex='1'>
                <Flex>
                    <Box as="div" pt={"6px"} pe={"8px"}>
                        <FaCheckCircle color="#3DED97" size={"20px"}/>
                    </Box>
                    <Box as="div">
                        <Text
                            as={"div"}
                            fontFamily={"heading"}
                            color={"brand.white"}
                            fontSize={"xl"}
                            lineHeight={"2xl"}
                            fontWeight={"600"}
                            position={"relative"}
                            pb={"10px"}
                        >
                            JUP Access: Enabled
                        </Text>
                    </Box>
                </Flex>
                
              </Box>
              <Box width={{ base: "20px", md: "48px" }} h={{ base: "20px", md: "40px" }}></Box>
              <Box ps={{ base: '0px', md: '18px' }} pe={{ base: '10px', md: '18px' }} pb={{ base: '14px', md: '0px' }} textAlign={"center"} pt={{base: "0px", md: "1"}}>
                {/* <Flex flexDirection={{ base: "row", md: "column" }} justifyContent={{ base: "space-between", md: "flex-start" }}>
                  <Box>
                    <Text
                      as={"span"}
                      fontSize={"32px"}
                      lineHeight={{ base: "45px", md: "34px" }}
                      fontWeight={"700"}
                      color={"brand.white"}
                      fontFamily={"heading"}
                    >
                      $399
                    </Text>
                    <Box>
                      <Text
                        as={"div"}
                        fontSize={"13px"}
                        fontWeight={"300"}
                        color={"brand.lighterWhite"}
                        fontFamily={"heading"}
                        mt={"-4px"}
                        pb={2}
                      >
                        PER MONTH
                      </Text>
                    </Box>
                  </Box>
                  <Box>
                    <Button
                      as={"a"}
                      href="https://discord.gg/8JyZCjRPmr"
                      target="_blank"
                      py={"8px"}
                      display={"block"}
                      size={"xs"}
                      width={"28"}
                      height={"32px"}
                      margin={"0 auto"}
                      bgColor={'brand.red'}
                      color={'brand.white'}
                      borderRadius={"full"}
                      textColor={"#fff"}
                      fontFamily={"heading"}
                      fontWeight={"400"}
                      _hover={{
                        bgColor: 'brand.white',
                        color: 'brand.red',
                        textDecoration: 'none',
                      }}
                      mt={{ base: 4, md: 1, lg: 0.5 }}
                    //   onClick={selectPlanAndPay}
                    >
                      Get Access
                    </Button>
                  </Box>
                </Flex> */}
                <Text as={"div"} color={"brand.lighterWhite"} fontSize={{ base: "14px", lg: "md" }}>
                    {/* Billing Cycle: <Text as={"span"} color={"brand.yellow"}>31 Dec 2024 - 1 Jan 2025</Text> */}
                    {/* Billing Cycle: -- */}
                </Text>

              </Box>
            </Flex>
          </Box>
        </Box>}

        <Box>
          <Text
            fontSize={{ base: 'lg', md: 'xl' }}
            fontFamily={'heading'}
            fontWeight={'600'}
            color={'brand.white'}
          >
            JUP API URL
          </Text>
        </Box>
        <Box as="div">
          {/* <GrpcMap /> */}
          <JupMap />
        </Box>


        <Box mt={12} pb={10}>
          <Flex justifyContent={'space-between'} alignItems={'center'} px={1}>
            <Text
              fontSize={{ base: 'lg', md: 'xl' }}
              fontFamily={'heading'}
              fontWeight={'600'}
              color={'brand.white'}
            >
              Support
            </Text>
          </Flex>
          <Flex
            direction={{ base: 'column', md: 'row' }}
            justifyContent={{ base: 'center', md: 'space-between' }}
            alignItems={'center'}
          >
            <Box w={'full'} maxW={{ base: '100%', lg: '49%' }}>
              <LinkDarkCards title='Telegram' link='https://t.me/+elnozTFe3OY0NDA1' />
            </Box>
            <Box w={'full'} maxW={{ base: '100%', lg: '49%' }}>
              <LinkDarkCards title='Discord' link={linksConfig.DISCORD_LINK} />
            </Box>
          </Flex>
        </Box>
      </Container>
    </div>

  );
};

export default JupApi;
