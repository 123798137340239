import React, { useState, useEffect, useRef } from "react";

// import { useElements, useStripe } from "@stripe/react-stripe-js";

import { Country, State, City } from "country-state-city";
import Select from "react-select";
import { ReactSession } from "react-client-session";
// import { CardElement } from "@stripe/react-stripe-js";
// import { Stripe } from 'stripe';
// import { useNavigate } from "react-router";

// import MainStyles from "../../resources/css/css.module.css";
import MainStyles from "../Dashboard/DashboardStyles/showCardDetailsStyles.module.css";
import LoadingStyles from "../../resources/css/loadingAnime.module.css";
import Cross from "../../resources/images/circle-close.svg";
// import StripeLong from "../../resources/images/stripe-badge-transparent.png";
import StripeCustom from "../../resources/images/powered_by_stripe_custom.svg";
// import StripeMedium from "../../resources/images/stripe-badge-opti.png";
import axios from "axios";
import { linksConfig } from "../../utils/CommonLinks";

const CardAdd = (props) => {
    // const navigate = useNavigate();
    // const stripe = useStripe();

    // const elements = useElements();
    // const card = useRef();

    // const cardStyle = {
    //     style: {
    //         base: {
    //             color: "#ffffff",
    //             fontFamily: `${'Poppins'}, sans-serif`,
    //             fontSmoothing: "antialiased",
    //             fontSize: "16px",
    //             "::placeholder": {
    //                 color: "#808080cc"
    //             }
    //         },
    //         invalid: {
    //             fontFamily: 'Poppins, sans-serif',
    //             color: "#fa755a",
    //             iconColor: "#fa755a"
    //         }
    //     }
    // };

    
    const dropstyles = {
        option: (provided, state) => ({
            ...provided,
            fontWeight: state.isSelected ? "bold" : "normal",
            //   backgroundColor: (state.isFocused ? (state.isSelected? "purple":"pink") : "#111"),
            color: "white",
            fontSize: "14px",
            fontFamily: "Jost"
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: "#fff",
            fontSize: "14px",
            fontFamily: "Jost"
        })
    };

    useEffect(() => {
        const allCountry = Country.getAllCountries();

        setLocations((prev) => {
            return { ...prev, countries: parseForSelect(allCountry) };
        });
    }, []);

    const [cardInfo, setCardInfo] = useState({
        name: "",
        expiry: "",
        number: "",
        address: {
            line: "",
            postalCode: "",
        },
    });
    // const [nameErr, setNameErr] = useState('');
    // const [addrErr, setAddrErr] = useState('');
    // // const [emailErr, setEmailErr] = useState('');
    // const [cardErr, setCardErr] = useState('');

    const [countryErr, setCountryErr] = useState('');
    // const [stateErr, setStateErr] = useState('');
    // const [cityErr, setCityErr] = useState('');

    // const [postCodeErr, setPostCodeErr] = useState('');
    const [agreeTerms, setAgreeTerms] = useState(false);

    const [Errors, setErrors] = useState();

    const [formState, setFormState] = useState('unsubmitted');
    const [locations, setLocations] = useState({ countries: "", states: "", cities: "" });
    const [selectedLocation, setSelectedLocation] = useState({ country: { "label": "", "value": "" }, city: { "label": "", "value": "" }, state: { "label": "", "value": "" } });

    // function handleChangeAddressLine(e) {
    //     const { value } = e.target;
    //     setCardInfo((prev) => {
    //         return { ...prev, address: { ...prev.address, line: value } };
    //     });
    // }

    // function handleChangePostalCode(e) {
    //     const { value } = e.target;
    //     setCardInfo((prev) => {
    //         return { ...prev, address: { ...prev.address, postalCode: value } };
    //     });
    // }

    // function handleChangeName(e) {
    //     const { value } = e.target;
    //     setCardInfo((prev) => {
    //         return { ...prev, name: value };
    //     });
    // }

    function parseForSelect(arr) {
        return arr.map((item) => ({
            label: item.name,
            value: item.isoCode ? item.isoCode : item.name,
        }));
    }

    function handleSelectCountry(country) {
        const states = State.getStatesOfCountry(country.value);
        
        setSelectedLocation((prev) => {
            return { ...prev, country };
        });

        setLocations((prev) => ({ ...prev, states: parseForSelect(states) }));
    }

    // function handleSelectState(state) {
    //     const cities = City.getCitiesOfState(selectedLocation.country.value, state.value);
    //     setSelectedLocation((prev) => {
    //         return { ...prev, state };
    //     });

    //     setLocations((prev) => ({ ...prev, cities: parseForSelect(cities) }));
    // }

    // function handleSelectCity(city) {
    //     setSelectedLocation((prev) => {
    //         return { ...prev, city };
    //     });
    // }
    async function handleSubmit() {
        setFormState('loading');

        const country = selectedLocation.country.value;
        var err_occ = false;
        if(country == "IN" && props.planName == "gRPC") {
            err_occ = true;
            setCountryErr("INGRPC");
        }
        
        if (err_occ == false) {
            
            //setFormState('loading');
            const token = ReactSession.get("xTkn") ?? "";
            axios({
                url: `${process.env.REACT_APP_AUTH_EP}dashboard/subscribe`,
                method: "POST",
                headers:
                {
                    "ngrok-skip-browser-warning": "69420",
                    'Authorization': `Bearer ${token}`
                },
                data: {
                    // name: billingDetails.name,
                    // address: billingDetails.address,
                    // payment_method_id: payment_method_confirmed.id,
                    country: country,
                    plan_type: props.planName,
                    plan_id: props.plan
                }
            })
                .then((res) => {
                    //console.log(res.data.success);
                    if (res.data.success === true) {
                        //setFormState('success');
                        //props.setShowCard(false);
                        //setFormState('unsubmitted');
                        try {
                            const stripeUrl = res.data.result.url;
                            if (stripeUrl) {
                                setFormState('unsubmitted'); 
                                props.setShowCard(false);
                                // navigate(stripeUrl);
                                window.location.replace(stripeUrl)
                            }
                            else
                            {
                                setErrors("Could not get the payment URL");
                                setFormState('unsubmitted');
                            }
                        } catch (error) {
                            setFormState('failed');
                            setErrors("Could not get payment URL");
                            //props.setCurrentPlan(props.plan);
                            setTimeout(() => {
                                //props.setShowCard(false);
                                setFormState('unsubmitted');
                                // props.setFreeUser(false);
                                // ReactSession.set("ifr", "paid");
                            }, 1000);
                            
                        }
                        
                        //props.setCurrentPlan(props.plan);
                        // setTimeout(() => {
                        //     props.setShowCard(false);
                        //     setFormState('unsubmitted');
                        //     props.setFreeUser(false);
                        //     ReactSession.set("ifr", "paid");
                        // }, 1000);
                    }
                    else {
                        setFormState('failed');
                        setErrors("Please Try Again");
                        setTimeout(() => {
                            // props.setShowCard(false);
                            setFormState('unsubmitted');
                        }, 1000);
                    }
                })
                // Catch errors if any
                .catch((err) => {
                    console.warn(err);
                    setFormState('failed');
                    setErrors("Some Error Occured");
                    setTimeout(() => {
                        // props.setShowCard(false);
                        setFormState('unsubmitted');
                    }, 1000);
                    // setErrOcc(true);
                    // props.setLoading(false);
                });
            
        }
        else
        {
            setFormState('unsubmitted');
        }

    }
    return (
        <div className="modal" style={{ display: "block", backdropFilter: "blur(4px)" }}>
            <div className="modal-dialog modal-dialog-scrollable" >
                <div className="modal-content" style={{ backgroundColor: "#111", borderRadius: "16px" }}>

                    <div className="modal-body">
                        <div className={MainStyles.dark_modal_style}>
                            <div className={MainStyles.cross_container}>
                                <button onClick={() => props.setShowCard(false)}>
                                    <img src={Cross} alt="close" />
                                </button>
                            </div>
                            <div className={`text-center pt-3 ${MainStyles.dashboard_main_title}`} style={{fontFamily: "Kanit"}}>
                                {/* <h3>Add Card</h3> */}
                                <h3>{props.planName ?? "---"} PLAN</h3>
                            </div>
                            <div className={MainStyles.card_input_forms}>
                                <div className="row">
                                    <div className="col-12 col-lg-12">
                                        <div className={MainStyles.dark_form_field}>
                                            
                                            <div className="pt-4">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <label>Payment Method Country</label>
                                                        <div className={MainStyles.form_grad_dropdowns_only}>
                                                            <div className={MainStyles.form_grad_dropdowns_inner}>
                                                                <Select
                                                                    isClearable={false}
                                                                    isSearchable={true}
                                                                    name="country"
                                                                    value={(selectedLocation.country.label === "") ? null : selectedLocation.country}
                                                                    options={locations.countries}
                                                                    onChange={handleSelectCountry}
                                                                    placeholder={<div style={{ font: "300 15px Jost", letterSpacing: "1px" }}>Select Country</div>}
                                                                    theme={(theme) => ({
                                                                        ...theme,
                                                                        borderRadius: "20px",
                                                                        colors: {
                                                                            ...theme.colors,
                                                                            neutral0: '#111', //the background
                                                                            neutral80: "#fff", //the selected text
                                                                            neutral60: "grey", //cross and drowdowns
                                                                            neutral50: "grey",
                                                                            neutral30: "transparent", //hover outline
                                                                            neutral20: "transparent", //selected outline
                                                                            primary: 'transparent',// normal outline and outer border
                                                                            primary25: 'red', //select hover color
                                                                        },
                                                                    })}
                                                                    styles={dropstyles}
                                                                />
                                                            </div>
                                                        </div>
                                                        {(countryErr === "INGRPC")?<small className={MainStyles.generic_error} style={{ fontFamily: "Jost" }}>Please reach out to us on <a href={linksConfig.DISCORD_LINK} target="_blank" rel="noreferrer">discord</a> or Dashboard Support to get access to gRPC</small>:<small className={MainStyles.generic_error} style={{ fontFamily: "Jost" }}>{countryErr}</small>}
                                                    </div>
                                                    {/* <div className="col-5">
                                                        <label>Postal Code</label>
                                                        <div className={MainStyles.dark_form_group}>
                                                            <div>
                                                                <input onChange={handleChangePostalCode} type="number" className="form-control" placeholder="Zip Code" />
                                                            </div>
                                                        </div>
                                                        <small className={MainStyles.generic_error} style={{ fontFamily: "Poppins" }}>{postCodeErr}</small>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={`text-start ${MainStyles.dark_form_field}`} style={{ marginTop: "-16px" }}>
                                    {/* <div className="pt-2">
                                        <label> <input type="checkbox" style={{ accentColor: "black" }} />&nbsp; &nbsp;Save my card with secure 1 - click checkout</label>
                                        
                                    </div> */}
                                    <div className="pt-2">
                                        <label> <input type="checkbox" style={{ accentColor: "black" }} onChange={(e) => {
                                            setAgreeTerms(!agreeTerms);
                                        }} />&nbsp;&nbsp; I agree to the <a href="https://shyft.to/terms" className={MainStyles.white_links} >Terms</a> and <a href="https://shyft.to/privacy-policy" className={MainStyles.white_links}>Privacy Policy</a></label>
                                        <br />
                                        <small className="text-warning" style={{ fontFamily: "Jost" }}>{ }</small>
                                    </div>
                                    
                                </div>

                                {/* <div className={`text-start ${MainStyles.dark_form_field}`} style={{ marginTop: "-16px" }}>
                                    <label> <input type="checkbox" style={{ accentColor: "black" }} onChange={(e) => {
                                        setAgreeTerms(!agreeTerms);
                                    }} /> I agree to the <a href="https://shyft.to/terms" className={MainStyles.white_links} >Terms</a> and <a href="https://shyft.to/privacy-policy" className={MainStyles.white_links}>Privacy Policy</a></label>
                                    <br />
                                    <small className="text-warning" style={{ fontFamily: "Poppins" }}>{ }</small>
                                </div> */}
                                <div className="py-1">
                                    {
                                        (formState === 'unsubmitted') && <button className={`${MainStyles.btn_solid_grad_full_width}`} onClick={handleSubmit}>
                                            Pay Now
                                        </button>
                                    }
                                    {
                                        (formState === 'loading') && <button className={`${MainStyles.btn_solid_grad_full_width} ${MainStyles.loading}`}>
                                            <div class={LoadingStyles.lds_ellipsis}>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                            </div>
                                        </button>
                                    }
                                    {
                                        (formState === 'success') && <button className={`${MainStyles.btn_solid_grad_full_width} ${MainStyles.success}`}>
                                            Success
                                        </button>
                                    }
                                    {
                                        (formState === 'failed') && <button className={`${MainStyles.btn_solid_grad_full_width} ${MainStyles.failed}`}>
                                            Failed
                                        </button>
                                    }

                                </div>
                                <div className="text-center">

                                    <small className={MainStyles.generic_error} style={{ fontFamily: "Jost" }}>{Errors}</small>
                                </div>
                                
                                <div className={MainStyles.stripe_logo_long}>
                                    <img src={StripeCustom} className="img-fluid" alt="Powered by stripe" />
                                </div>
                                
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CardAdd;