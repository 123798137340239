import { useEffect, useState } from "react";
import {
  Box,
  Text,
  Flex,
  VisuallyHidden,
  chakra,
  Container,
  Button,
  IconButton,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Editable,
  EditableInput,
  EditableTextarea,
  EditablePreview,
  useEditableControls,
  Input,
  ButtonGroup
} from "@chakra-ui/react";
import { FaCopy, FaCheckSquare, FaTrashAlt, FaCheck, FaEdit, FaWindowClose, FaWalking } from "react-icons/fa";
import { BsFillGearFill } from "react-icons/bs";
import { BeatLoader } from 'react-spinners';
import { ImCross } from "react-icons/im";
import axios from "axios";
import { ReactSession } from "react-client-session";
// import { PopupButton } from "react-calendly";
import GrpcMap from "./GrpcMap";
// import { ExternalLinkIcon } from '@chakra-ui/icons';

import LinkDarkCards from "./LinkDarkCards";
import { linksConfig } from "../../utils/CommonLinks";


const SocialButton = ({ children, label, href }) => {
  return (
    <chakra.button
      bg={"brand.red"}
      color={"brand.white"}
      rounded={"full"}
      w={8}
      h={8}
      cursor={"pointer"}
      as={"a"}
      href={href}
      target="_blank"
      display={"inline-flex"}
      alignItems={"center"}
      justifyContent={"center"}
      transition={"background 0.3s ease"}
      _hover={{
        bg: "brand.white",
        color: "brand.red",
      }}
    >
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  );
};

function CustomControlsExample(props) {
  /* Here's a custom control */
  function EditableControls(props) {
    const {
      isEditing,
      getSubmitButtonProps,
      getCancelButtonProps,
      getEditButtonProps
    } = useEditableControls()

    const [deleteNow, setDeleteNow] = useState(false);

    return isEditing ? (
      <ButtonGroup justifyContent='flex-end' size='sm' width={"full"}>
        <IconButton icon={<FaCheck />} {...getSubmitButtonProps()} bg={"transparent"} border={"1px solid"} borderColor={"brand.red"} color={"brand.white"} _hover={{ bg: "brand.red" }} />
        <IconButton icon={<FaWindowClose />} {...getCancelButtonProps()} bg={"transparent"} border={"1px solid"} borderColor={"brand.red"} color={"brand.white"} _hover={{ bg: "brand.red" }} />
      </ButtonGroup>
    ) : (
      deleteNow ? <Flex justifyContent='flex-end'>
        <Box as="div" display={"inline"}>
          <Text as={"span"} color={"brand.red"}>Delete?</Text>
        </Box>
        <IconButton size={"sm"} bg={"transparent"} border={"1px solid"} borderColor={"brand.red"} color={"brand.white"} _hover={{ bg: "brand.red" }} ms={2} onClick={() => props.deleteIp(props.deletevalue)}><FaCheck size={"14"} color="#fff" /></IconButton>
        <IconButton size={"sm"} bg={"transparent"} border={"1px solid"} borderColor={"brand.red"} color={"brand.white"} _hover={{ bg: "brand.red" }} ms={2} onClick={() => setDeleteNow(false)}><ImCross size={"14"} color="#fff" /></IconButton>
      </Flex>
        :
        <Flex justifyContent='flex-end'>
          <IconButton size='sm' icon={<FaEdit />} {...getEditButtonProps()} bg={"transparent"} border={"1px solid"} borderColor={"brand.red"} color={"brand.white"} _hover={{ bg: "brand.red" }}/>
          <IconButton size={"sm"} bg={"brand.red"} border={"1px solid"} borderColor={"brand.red"} color={"brand.white"} _hover={{ bg: "brand.red" }} ms={2} onClick={() => setDeleteNow(true)}><FaTrashAlt size={"14"} color="#fff" /></IconButton>
        </Flex>
    )
  }

  return (
    <Editable
      textAlign='start'
      defaultValue={props.textvalue}
      fontSize='md'
      isPreviewFocusable={false}
      onSubmit={(input) => {
        props.updateIps(props.deletevalue, input)
      }}
    >
      <Flex justifyContent='space-between' w={"100%"}>
        <Box flex={1} ps={1}>
          {props.id}.
        </Box>
        <Box flex={4}>
          <Box marginTop={"-1"}>
            <EditablePreview />
            <Input as={EditableInput} />
          </Box>

        </Box>
        <Box flex={2} w={"200px"}>
          <EditableControls deletevalue={props.deletevalue} navigate={props.navigate} deleteIp={props.deleteIp} />
        </Box>
      </Flex>
    </Editable>
  )
}

const Grpcs = (props) => {

  const [data, setData] = useState(null);
  const [copied, setCopied] = useState(false);
  const [errOcc, setErrOcc] = useState(false);

  const [currentIps, setCurrentIps] = useState([]);
  const [showIps, setShowIps] = useState(false);
  const [showAddPanel, setShowAddPanel] = useState(false);

  const [ipAdded, setIpAdded] = useState("");
  const [ipAddStatus, setIpAddStatus] = useState("UNLOADED");
  //can be UNLOADED,LOADING,SUCCESS,ERROR
  const [ipAddError, setIpAddError] = useState("");

  const [ipEditStatus, setIpEditStatus] = useState("UNLOADED");
  const [ipEditError, setIpEditError] = useState("");
  const [ipEditValue, setIpEditValue] = useState("");


  useEffect(() => {
    const token = ReactSession.get("xTkn") ?? "";

    if (token !== "") {
      const endPoint = process.env.REACT_APP_AUTH_EP;
      axios({
        url: `${endPoint}dashboard/get_grpc_details`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "ngrok-skip-browser-warning": "69420",
        },
      })
        .then((res) => {
          if (res.data.success === true) {
            if(!res.data?.result?.hasOwnProperty("ip_allowed"))
              res.data.result["ip_allowed"] = 1;
            setData(res.data.result);
            setCurrentIps(res.data?.result?.whitelisted_ips ?? []);
          } else {
            setErrOcc(true);
          }
        })
        // Catch errors if any
        .catch((err) => {
          console.warn(err);
          setErrOcc(true);
          props.setLoading(false);
        });
    } else {
      //navigate("/get-api-key");
    }
  }, []);



  useEffect(() => {
    // setTimeout(() => {
    //   props.setLoading(false);
    // },800)
    if (data !== null || errOcc === true) props.setLoading(false);
  }, [data, errOcc]);

  const copyGrpcToken = () => {
    navigator.clipboard.writeText(data?.token ?? "--");
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  const selectPlanAndPay = () => {
    props.setPlan("665d7fda4c2f3b7a8d629933");
    props.setPlanName("gRPC");
    props.setShowCard(true);
  };

  const addNewIp = () => {
    setIpAddError("");
    const inputIpAddress = ipAdded.trim();
    if (validateIPaddress(inputIpAddress)) {
      setIpAddStatus("LOADING");
      const token = ReactSession.get("xTkn") ?? "";
      const endPoint = process.env.REACT_APP_AUTH_EP;
      axios({
        url: `${endPoint}dashboard/whitelist_ips`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "ngrok-skip-browser-warning": "69420",
        },
        data: {
          ips: [inputIpAddress]
        }
      })
        .then((res) => {
          if (res.data.success === true) {
            setIpAddStatus("SUCCESS");
            setIpAddError("");
            setCurrentIps(res.data?.result ?? []);
            setTimeout(() => {
              setIpAddStatus("UNLOADED");
              setShowAddPanel(false);
              setShowIps(false);
              //props.navigate(0);
            }, 1500);
          } else {
            setIpAddStatus("ERROR");
            setIpAddError(res.data.error);
          }
        })
        // Catch errors if any
        .catch((err) => {
          console.warn(err);
          setIpAddStatus("ERROR");
          setIpAddError(err.response?.data?.error?.toString() ?? err.message);
          setTimeout(() => {
            setIpAddStatus("UNLOADED");
            setShowAddPanel(false);
            setIpAddError("");
          }, 2000);
        });
    } else {
      setIpAddError("Please enter a valid IPv4 Address");
    }
  };


  const updateIps = (existingIp, newIp) => {
    setIpEditError("");
    existingIp = existingIp.trim();
    newIp = newIp.trim();
    const token = ReactSession.get("xTkn") ?? "";
    const endPoint = process.env.REACT_APP_AUTH_EP;
    if (newIp === existingIp) {
      console.log("IPS are the same");
      return
    }
    if (token === "")
      return;
    if (validateIPaddress(newIp) && validateIPaddress(existingIp)) {
      setIpEditStatus("LOADING");
      axios({
        url: `${endPoint}dashboard/change_whitelisted_ip`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "ngrok-skip-browser-warning": "69420",
        },
        data: {
          new_ip: newIp,
          existing_ip: existingIp
        }
      })
        .then((res) => {
          if (res.data.success === true) {
            setIpEditStatus("SUCCESS");
            setIpEditError("");
            setCurrentIps(res.data?.result ?? []);
            setTimeout(() => {
              setIpEditStatus("UNLOADED");
              setShowIps(false);
            }, 1500);

          }
        })
        // Catch errors if any
        .catch((err) => {
          console.warn(err);
          setIpEditError(err.response?.data?.error?.toString() ?? err.message);
          setIpEditStatus("ERROR");
          setTimeout(() => {
            setIpEditStatus("UNLOADED");
          }, 2500);
        });
    }
    else {
      setIpEditError("Please enter a valid IPv4 Address");
      setTimeout(() => {
        setIpEditError("");
        setShowIps(false);
      }, 3000);
    }
  };

  const deleteIp = (ipToDelete) => {
    setIpEditError("");
    console.log("Deleting IP: ", ipToDelete);

    const token = ReactSession.get("xTkn") ?? "";

    if (token === "")
      return;

    if (!validateIPaddress(ipToDelete)) {
      setIpEditError("Invalid Ip to delete");
      setShowIps(false);
    }
    setIpEditStatus("LOADING");
    const endPoint = process.env.REACT_APP_AUTH_EP;

    axios({
      url: `${endPoint}dashboard/remove_whitelisted_ips`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "ngrok-skip-browser-warning": "69420",
      },
      data: {
        ips: [ipToDelete]
      }
    })
      .then((res) => {
        if (res.data.success === true) {
          setIpEditStatus("SUCCESS");
          setIpEditError("");
          setCurrentIps(res.data?.result ?? []);
          // setDeleteNow(false);
          setTimeout(() => {
            setIpEditStatus("UNLOADED");
            // setShowAddPanel(false);
            // setShowIps(false);
            //props.navigate(0);
          }, 1500);
        }
      })
      .catch((err) => {
        console.log(err);
        setIpEditStatus("ERROR");
        setIpEditError(err.response?.data?.error?.toString() ?? err.message);
        setTimeout(() => {
          setIpEditStatus("UNLOADED");
        }, 2500);
      });


  }

  function validateIPaddress(ipaddress) {
    if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ipaddress)) {
      return (true)
    }
    return (false)
  }
  return (
    <div>
      <Modal isOpen={showIps} onClose={() => setShowIps(false)} blockScrollOnMount={true} size={"lg"}>
        <ModalOverlay />
        <ModalContent bg={"brand.black"} border={"1px solid"} borderColor={"brand.midGrey"} borderRadius={"xl"}>
          {ipEditStatus === "LOADING" && <Box bg={"#0a0a0aaa"} width={"full"} height={"94%"} position={"absolute"} zIndex={99} marginTop={"10px"} borderRadius={"10px"}>
            <Box position={"relative"} zIndex={200} marginLeft={"40%"} marginTop={"80px"}>
              <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
            </Box>
          </Box>}
          {ipEditStatus === "SUCCESS" && <Box bg={"#0a0a0aaa"} width={"full"} height={"94%"} position={"absolute"} zIndex={99} marginTop={"10px"} borderRadius={"10px"}>
            <Flex width={"full"} height={"full"} justifyContent={"center"} alignItems={"center"} zIndex={100}>
              <FaCheck size={32} color={"green"} />
            </Flex>
          </Box>}
          {ipEditStatus === "ERROR" && <Box bg={"#0a0a0aaa"} width={"full"} height={"94%"} position={"absolute"} zIndex={99} marginTop={"10px"} borderRadius={"10px"}>
            <Flex width={"full"} height={"full"} justifyContent={"center"} alignItems={"center"} zIndex={100}>
              <ImCross size={32} color={"red"} />
            </Flex>
          </Box>}
          <ModalCloseButton color={"brand.white"} />
          <ModalBody py={4}>
            {showAddPanel ?
              <Box>
                <Text
                  as="div"
                  fontSize={{ base: "md", md: "xl" }}
                  fontWeight={"600"}
                  color={"brand.white"}
                  textAlign={"center"}
                  fontFamily={"heading"}
                  paddingBottom={8}
                >
                  Whitelist New IP
                </Text>
                <Box>
                  <Flex>
                    <Input type="text" placeholder="whitelist a new IP address" w={"full"} bg={"brand.black"} border={"1px solid"} borderColor={"brand.midGrey"} color={"brand.white"} size={"sm"} borderRadius={"8px"} onChange={(e) => setIpAdded(e.target.value)} />
                    <Box w={"8px"}></Box>
                    {ipAddStatus === "UNLOADED" && <Button bg={"brand.red"} color={"brand.white"} border={"1px solid"} borderColor={"brand.red"} _hover={{ bg: "brand.red" }} onClick={addNewIp} size={"sm"} borderRadius={"8px"} px={4}>Add</Button>}
                    {ipAddStatus === "LOADING" && <Box width={"50px"} pt={2}><BeatLoader color="#fff" loading={true} size={10} /></Box>}
                    {ipAddStatus === "SUCCESS" && <Box width={"50px"} pt={2} ps={4}><FaCheck color="#39e75f" /></Box>}
                    {ipAddStatus === "ERROR" && <Box width={"50px"} pt={2} ps={4}><ImCross color="#e3242b" /></Box>}
                  </Flex>
                  {ipAddError !== "" && <Text mt={4} textColor={"brand.red"} fontSize={"sm"}>{ipAddError}</Text>}
                </Box>
                <Flex justifyContent={"center"} width={"full"} mt={6} mb={1}>
                  <Button size={"sm"} bg={"black"} color={"brand.white"} border={"1px solid"} borderColor={"brand.red"} _hover={{ bg: "brand.red" }} onClick={() => setShowAddPanel(false)}>Cancel</Button>
                </Flex>
              </Box>
              : <Box>
                <Text
                  as="div"
                  fontSize={{ base: "md", md: "xl" }}
                  fontWeight={"600"}
                  color={"brand.white"}
                  textAlign={"center"}
                  fontFamily={"heading"}
                  paddingBottom={8}
                >
                  Whitelisted IPs
                </Text>
                {currentIps.length > 0 && <Box>
                  <Box borderBottom={"1px dotted"} borderColor={"#e3353566"} textColor={"brand.yellow"} fontWeight={"400"} fontSize={"sm"} pb={2} fontFamily={"heading"}>
                    <Flex>
                      <Box flex={1} ps={1}></Box>
                      <Box flex={4}>IP Address</Box>
                      <Box flex={2} width={"150px"} textAlign={"right"}></Box>
                    </Flex>
                  </Box>
                  <Box maxH={currentIps.length < 5 ? "auto": "400px"} overflowY={currentIps.length < 5? "auto": "scroll"}>
                    {/* map this section */}
                    {currentIps.map((ipAddress, index) => <Box key={index} textColor={"brand.lighterWhite"} fontWeight={"400"} fontSize={"sm"} pt={3} pb={1} fontFamily={"heading"}>
                      <CustomControlsExample id={index + 1} textvalue={ipAddress} deletevalue={ipAddress} setShowIps={setShowIps} navigate={props.navigate} currentIps={currentIps} setCurrentIps={setCurrentIps} ipEditError={ipEditError} setIpEditError={setIpEditError} ipEditValue={ipEditValue} setIpEditValue={setIpEditValue} updateIps={updateIps} validateIPaddress={validateIPaddress} ipEditStatus={ipEditStatus} deleteIp={deleteIp} />
                    </Box>)}
                  </Box>
                  
                  {ipEditError !== "" && <Text color={"brand.red"} fontSize={"xs"}>{ipEditError}</Text>}
                  <Box borderTop={"1px dashed"} borderColor={"#d9d9d988"} marginTop={"10px"}></Box>
                </Box>}
                {currentIps.length === 0 &&
                  <Box>
                    <Text color={"brand.lighterWhite"} fontSize={"sm"}>You have not whitelisted any IP addresses.</Text>
                  </Box>
                }
                <Text color={"brand.lighterWhite"} fontSize={"sm"} mt={6}>{ipEditValue}</Text>
                {data?.ip_allowed > currentIps.length ? <Flex justifyContent={"center"} width={"full"} mt={6} mb={1}>
                  <Button size={"sm"} bg={"black"} color={"brand.white"} border={"1px solid"} borderColor={"brand.red"} _hover={{ bg: "brand.red" }} onClick={() => setShowAddPanel(true)}>Add New IP</Button>
                </Flex> : <Box w={"full"} height={"18px"}></Box>}
              </Box>}
          </ModalBody>
          {/* <ModalFooter> */}

          {/* {cancelStatus === "CANCELLING" && <Flex w={"100%"} justifyContent={"center"} height={"24px"}>
                      <BeatLoader color="#fff" loading={true} size={10} />
                  </Flex>}
                  {cancelStatus === "CANCELLED" && <Flex w={"100%"} justifyContent={"center"} height={"24px"}>
                      <FaCheck color="#39e75f"/>
                  </Flex>}
                  {cancelStatus === "NOT_CANCELLED" && <Flex w={"100%"} justifyContent={"center"} height={"24px"}>
                      <ImCross color="#e3242b"/>
                  </Flex>} */}

          {/* </ModalFooter> */}
        </ModalContent>
      </Modal>
      <Container maxW={"8xl"}>
        <Box
          as="div"
          // px={{ base: '16px', md: '24px', xl: '32px' }}
          // py={{ base: '16px', md: '24px' }}
          borderRadius={"14px"}
        >
          <Box>
            <Text
              as={"div"}
              fontFamily={"heading"}
              color={"brand.white"}
              fontSize={"2xl"}
              lineHeight={"3xl"}
              fontWeight={"600"}
              position={"relative"}
            >
              Shyft gRPC Nodes

            </Text>

            <Text
              as={"div"}
              fontSize={{ base: "14px", lg: "md" }}
              fontWeight={"400"}
              color={"brand.lighterWhite"}
              pt={{ base: "4px", md: "6px" }}
            >
              Supercharge your Solana streaming experience. Choose the region closest to you and start streaming, without any credits or metering.
            </Text>
          </Box>
        </Box>
        
        {(data === null) ? <Box pt={8} pb={8}>
          <Box
            border={"2px solid"}
            borderColor={"brand.lightGray"}
            borderRadius={"14px"}
            _hover={{
              borderColor: "brand.red"
            }}
            backgroundColor={"black"}
            p={4}
            pb={4}
            ps={6}
          >
            <Flex flexDirection={{ base: "column", md: "row" }} justifyContent={{ base: "center", md: "space-between" }} alignItems={{ base: "initial", md: "center", xl: "start" }}>
              <Box flex='1'>
                <Text
                  as={"div"}
                  fontFamily={"heading"}
                  color={"brand.white"}
                  fontSize={"xl"}
                  lineHeight={"2xl"}
                  fontWeight={"600"}
                  position={"relative"}
                  pb={"10px"}
                >
                  Flat $199 / month / IP
                </Text>
                <Text as={"div"} color={"brand.lighterWhite"} fontSize={{ base: "14px", lg: "md" }}>
                  No credits, no metering. Each region is powered by multiple nodes for fault tolerance.
                </Text>
                <Text as={"div"} color={"brand.lighterWhite"} pt={"10px"} fontWeight={500} fontSize={{ base: "14px", lg: "md" }}>
                  Reach out to us on <Text color={"brand.yellow"} _hover={{ color: "brand.red" }}>dashboard support</Text> for queries.
                </Text>
              </Box>
              <Box width={{ base: "20px", md: "48px" }} h={{ base: "20px", md: "40px" }}></Box>
              <Box ps={{ base: '0px', md: '18px' }} pe={{ base: '10px', md: '18px' }} textAlign={"center"}>
                <Flex flexDirection={{ base: "row", md: "column" }} justifyContent={{ base: "space-between", md: "flex-start" }}>
                  <Box>
                    <Text
                      as={"span"}
                      fontSize={"4xl"}
                      lineHeight={{ base: "45px", md: "34px" }}
                      fontWeight={"700"}
                      color={"brand.white"}
                      fontFamily={"heading"}
                    >
                      $199
                    </Text>
                    <Box>
                      <Text
                        as={"div"}
                        fontSize={"13px"}
                        fontWeight={"300"}
                        color={"brand.lighterWhite"}
                        fontFamily={"heading"}
                        mt={"-4px"}
                        pb={2}
                      >
                        PER MONTH
                      </Text>
                    </Box>
                  </Box>
                  <Box>
                    <Button
                      py={"8px"}
                      display={"block"}
                      size={"xs"}
                      width={"28"}
                      height={"32px"}
                      margin={"0 auto"}
                      bgColor={'brand.red'}
                      color={'brand.white'}
                      borderRadius={"full"}
                      textColor={"#fff"}
                      fontFamily={"heading"}
                      fontWeight={"400"}
                      _hover={{
                        bgColor: 'brand.white',
                        color: 'brand.red',
                        textDecoration: 'none',
                      }}
                      mt={{ base: 3, md: 1, lg: 0.5 }}
                      onClick={selectPlanAndPay}
                    >
                      Buy gRPC
                    </Button>
                  </Box>
                </Flex>


              </Box>
            </Flex>
          </Box>
        </Box> :
          <Box pt={8} pb={8}>
            <Flex
              justifyContent={"space-between"}
              alignItems={"center"}
              pb={2}
              px={1}
            >
              <Text
                fontSize={{ base: 'lg', md: 'xl' }}
                fontFamily={'heading'}
                fontWeight={'600'}
                color={'brand.white'}
              >
                gRPC Token & Whitelisted IPs
              </Text>
              <Text
                as={'span'}
                fontSize={{ base: "12px", lg: "14px" }}
                fontWeight={"400"}
                color={"brand.lighterWhite"}
              >
                IPs Allowed:
                <Text
                  as={'span'} color={"brand.yellow"} fontWeight={"bold"}>{" "}{data.ip_allowed ?? 1} </Text>
              </Text>
            </Flex>
            <Box as="div">
              <Box
                as="div"
                bg="black"
                px={{ base: "16px", md: "24px", xl: "32px" }}
                py={{ base: "8px", md: "10px", xl: "10px" }}
                borderRadius={"14px"}
                border={"2px solid"}
                borderColor={"brand.red"}
              >
                <TableContainer>
                  <Table variant='simple' className="overview_table">
                    <Thead fontFamily={"heading"}>
                      <Tr>
                        <Th textTransform={"capitalize"} fontSize={"md"} fontWeight={"400"} letterSpacing={"0px"} width={"100px"}>{""}</Th>
                        <Th textTransform={"capitalize"} fontSize={"md"} fontWeight={"400"} letterSpacing={"0px"} textAlign={"start"}><Text as="span" color={"#fff"} fontWeight={"600"}>Token Address</Text></Th>
                        <Th textTransform={"capitalize"} fontSize={"md"} fontWeight={"400"} letterSpacing={"0px"} textAlign={"center"}><Text as="span" color={"#fff"} fontWeight={"600"}>Allowed IPs</Text></Th>

                        <Th isNumeric textTransform={"capitalize"} fontSize={"md"} fontWeight={"400"} letterSpacing={"0px"} width={"100px"}></Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      <Tr>
                        <Td width={"100px"}>1</Td>
                        <Td className="center">
                          <Flex
                            justifyContent={"start"}
                            alignItems={"center"}
                          >
                            <Text
                              as={"span"}
                              fontSize={{ base: "14px", lg: "18px" }}
                              letterSpacing={"0.02em"}
                              color={"brand.lighterWhite"}
                            >
                              {data?.token ?? "--"}
                            </Text>
                            <Box height={"1px"} w={"8px"}></Box>
                            {copied ? (
                              <IconButton
                                size={"sm"}
                                aria-label="Copy to clipboard"
                                icon={<FaCheckSquare size={"16"} />}
                                bg={"transparent"}
                                color={"green.300"}
                                _hover={{ bg: "transparent" }}
                              />
                            ) : (
                              <IconButton
                                size={"sm"}
                                aria-label="Copy to clipboard"
                                icon={<FaCopy size={"16"} />}
                                onClick={copyGrpcToken}
                                bg={"transparent"}
                                color={"brand.yellow"}
                                _hover={{ bg: "transparent" }}
                              />
                            )}
                          </Flex>

                        </Td>
                        <Td className="center">
                          {currentIps.length === 0 && <Text as={"span"} color={"brand.lighterWhite"}>All IPs Enabled</Text>}
                          {currentIps.length > 0 && <Box>
                            {currentIps.length > 0 && <Box as="div" display={"inline-block"} bgColor={"brand.red"} px={2} py={1} mx={1} borderRadius={6} fontSize={"0.9em"} fontWeight={"600"}>{currentIps[0]}</Box>}
                            {currentIps.length > 1 && <Box as="div" display={"inline-block"} bgColor={"brand.red"} px={2} py={1} mx={1} borderRadius={6} fontSize={"0.9em"} fontWeight={"600"}>{currentIps[1]}</Box>}
                            {currentIps.length > 2 && <Box as="div" display={"inline-block"} bgColor={"brand.yellow"} color={"brand.black"} px={2} py={1} mx={1} borderRadius={"full"} fontSize={"0.9em"} fontWeight={"600"}>+{currentIps.length - 2}</Box>}
                          </Box>}

                        </Td>

                        <Td width={"100px"}><IconButton size={"sm"} bg={"transparent"} color={"brand.yellow"} _hover={{ bg: "transparent" }} onClick={() => setShowIps(true)}><BsFillGearFill size={"20"} color="#fff" /></IconButton></Td>
                      </Tr>

                    </Tbody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
            <Box>
              <Text as={"div"} color={"brand.lighterWhite"} pt={"18px"} fontWeight={500} fontSize={{ base: "14px", lg: "sm" }}>
                Whitelisted IPs take a small time to propagate. Please reach out to us on <Text color={"brand.yellow"} _hover={{ color: "brand.red" }}>dashboard support</Text> if you face any issue.
              </Text>
            </Box>
          </Box>}

        <Box>
          <Text
            fontSize={{ base: 'lg', md: 'xl' }}
            fontFamily={'heading'}
            fontWeight={'600'}
            color={'brand.white'}
          >
            gRPC URL
          </Text>
        </Box>
        <Box as="div">
          <GrpcMap />
        </Box>


        <Box mt={12} pb={10}>
          <Flex justifyContent={'space-between'} alignItems={'center'} px={1}>
            <Text
              fontSize={{ base: 'lg', md: 'xl' }}
              fontFamily={'heading'}
              fontWeight={'600'}
              color={'brand.white'}
            >
              Support
            </Text>
          </Flex>
          <Flex
            direction={{ base: 'column', md: 'row' }}
            justifyContent={{ base: 'center', md: 'space-between' }}
            alignItems={'center'}
          >
            <Box w={'full'} maxW={{ base: '100%', lg: '49%' }}>
              <LinkDarkCards title='Telegram' link='https://t.me/+elnozTFe3OY0NDA1' />
            </Box>
            <Box w={'full'} maxW={{ base: '100%', lg: '49%' }}>
              <LinkDarkCards title='Discord' link={linksConfig.DISCORD_LINK} />
            </Box>
          </Flex>
        </Box>
      </Container>
    </div>

  );
};

export default Grpcs;
