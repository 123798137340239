import { useEffect } from "react";
import ReactGA from "react-ga4";

import GrpcStyles from "./grpc-styles-css.module.css";
import { Box, Container, Heading, Text, Button } from "@chakra-ui/react";

import GrpcStreamingMap from "./GrpcStreamingMap";
import ClientRollingSection from "../LandingPage/LandingPageComponents/ClientRollingSection";
import PricingStatic from "../LandingPage/LandingPageComponents/PricingStatic";

const Grpcstreaming = () => {
  useEffect(() => {
    document.title = "Shyft | Solana gRPC Streaming Service";
    ReactGA.send({
      hitType: "pageview",
      page: "/solana-yellowstone-grpc",
    });
  }, []);
  return (
    <>
      <Box as="section" className={`${GrpcStyles.hero}`}>
        <Container maxW={"7xl"}>
          <Heading as="h1" color={"white"}>
            Solana{" "}{" "}
            <Text as="span" style={{ color: "#ef4444" }}>
                gRPC
            </Text>{" "}
            Network{" "}
          </Heading>
          <Text as="p">
          Yellowstone gRPC plugin is the fastest way to stream Solana. Start with region-optimized network of gRPC nodes, built for performance and fault-tolerance. No credits, no metering, pure unlimited streaming
          </Text>
          <Box as="div" className={`${GrpcStyles.button_group}`}>
            <Button
              as={"a"}
              href="/dashboard/grpc"
              target="_blank"
              pt={"12px"}
              pb={"10px"}
              display={"block"}
              size={"sm"}
              // width={"28"}
              height={"10"}
              // maxW={"180px"}
              w={{ base: "full", lg: "160px" }}
              border={"1px solid"}
              borderColor={"brand.red"}
              bgColor={"brand.red"}
              color={"brand.white"}
              borderRadius={"full"}
              textColor={"#fff"}
              fontFamily={"heading"}
              fontWeight={"500"}
              _hover={{
                bgColor: "brand.white",
                color: "brand.red",
                borderColor: "brand.white",
                textDecoration: "none",
              }}
              mt={{ base: 2, md: 1, lg: 0 }}
              textAlign={"center"}
            >
              Start Streaming
            </Button>
            <Button
              as={"a"}
              href="https://docs.shyft.to/solana-grpc-shredstream/grpc-docs"
              target="_blank"
              pt={"12px"}
              pb={"10px"}
              display={"block"}
              size={"sm"}
              // width={"28"}
              height={"10"}
              // maxW={"300px"}
              w={{ base: "full", lg: "160px" }}
              border={"1px solid"}
              borderColor={"brand.red"}
              bgColor={"brand.black"}
              color={"brand.white"}
              borderRadius={"full"}
              textColor={"#fff"}
              fontFamily={"heading"}
              fontWeight={"500"}
              _hover={{
                bgColor: "brand.red",
                color: "brand.white",
                textDecoration: "none",
              }}
              mt={{ base: 2, md: 1, lg: 0 }}
              textAlign={"center"}
            >
              gRPC Docs
            </Button>
            <Button
              as={"a"}
              href="https://github.com/Shyft-to/solana-defi"
              target="_blank"
              pt={"12px"}
              pb={"10px"}
              display={"block"}
              size={"sm"}
              // width={"28"}
              height={"10"}
              // maxW={"300px"}
              w={{ base: "full", lg: "190px" }}
              border={"1px solid"}
              borderColor={"brand.red"}
              bgColor={"brand.black"}
              color={"brand.white"}
              borderRadius={"full"}
              textColor={"#fff"}
              fontFamily={"heading"}
              fontWeight={"500"}
              _hover={{
                bgColor: "brand.red",
                color: "brand.white",
                textDecoration: "none",
              }}
              mt={{ base: 2, md: 1, lg: 0 }}
              textAlign={"center"}
            >
              gRPC Code Samples
            </Button>
          </Box>
        </Container>
      </Box>

      <Box as="section">
            <GrpcStreamingMap />
        </Box>

      {/* <!-- Features Section --> */}
      {/* <Box as="section" className={JupStyles.features}>
            <Container maxW={"7xl"}>
                <Heading as="h2" className={JupStyles.section_title} textColor={"brand.white"}>Advanced DEX Aggregation Infrastructure</Heading>
                <Text as="p" className={JupStyles.section_description}>Get the most efficient token swaps with our optimized infrastructure and region-specific endpoints.</Text>
                
                <Box as="div" className={JupStyles.features_grid}>
                    <Box as="div" className={JupStyles.feature_card}>
                        <Box as="div" className={JupStyles.feature_icon}>🌐</Box>
                        <Heading as="h3" textColor={"brand.white"}>Region-Optimized</Heading>
                        <Text as="p">Connect to endpoints closest to your users and servers. New York, Ashburn, Amsterdam and Frankfurt.
                        </Text>
                    </Box>
                    <Box as="div" className={JupStyles.feature_card}>
                        <Box as="div" className={JupStyles.feature_icon}>⚡</Box>
                        <Heading as="h3" textColor={"brand.white"}>High Performance</Heading>
                        <Text as="p">150 requests per second with unlimited monthly calls.</Text>
                    </Box>
                    <Box as="div"  className={JupStyles.feature_card}>
                        <Box as="div" className={JupStyles.feature_icon}>🛡️</Box>
                        <Heading as="h3" textColor={"brand.white"}>No Credit System</Heading>
                        <Text as="p">Simple, transparent pricing with no usage limits or credit system.</Text>
                    </Box>
                </Box>
            </Container>
        </Box> */}
        <Box>
            <ClientRollingSection />
        </Box>
        <Box pb={8}>
            <PricingStatic />
        </Box>

      
    </>
  );
};

export default Grpcstreaming;
