import { useEffect, useState } from "react";
import {
  Box,
  Text,
  Flex,
  VisuallyHidden,
  chakra,
  Container,
  Button,
  IconButton,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Editable,
  EditableInput,
  EditableTextarea,
  EditablePreview,
  useEditableControls,
  Input,
  ButtonGroup,
} from "@chakra-ui/react";
import {
  FaCopy,
  FaCheckSquare,
  FaTrashAlt,
  FaCheck,
  FaEdit,
  FaWindowClose,
  FaWalking,
} from "react-icons/fa";
import { BsFillGearFill } from "react-icons/bs";
import { BeatLoader } from "react-spinners";
import { ImCross } from "react-icons/im";
import axios from "axios";
import { ReactSession } from "react-client-session";
// import { PopupButton } from "react-calendly";

import DedicatedStyles from "./DashboardStyles/dedicated-grpc.module.css";
// import { ExternalLinkIcon } from '@chakra-ui/icons';

import LinkDarkCards from "./LinkDarkCards";
import { linksConfig } from "../../utils/CommonLinks";

const DedicatedNodes = (props) => {
    useEffect(() => {
        setTimeout(() => {
          props.setLoading(false);
        },800)
        // if (data !== null || errOcc === true) props.setLoading(false);
      }, []);
  return (
    <div>
      <Container maxW={"8xl"}>
        <Box
          as="div"
          // px={{ base: '16px', md: '24px', xl: '32px' }}
          py={{ base: '16px', md: '24px' }}
          borderRadius={"14px"}
        >
          <Box>
            <Text
              as={"div"}
              fontFamily={"heading"}
              color={"brand.white"}
              fontSize={"2xl"}
              lineHeight={"3xl"}
              fontWeight={"600"}
              position={"relative"}
            >
              Shyft gRPC Nodes
            </Text>

            <Text
              as={"div"}
              fontSize={{ base: "14px", lg: "md" }}
              fontWeight={"400"}
              color={"brand.lighterWhite"}
              pt={{ base: "4px", md: "6px" }}
            >
              {/* Custom Nodes  */}
            </Text>
          </Box>
          <Box pt={8}>
            <Flex display={"flex"} flexWrap={"wrap"}>
                
                <Box flexGrow={1} pe={{base: 0, md: 6, lg: 6}} >
                    <Box mh={"50px"} pb={{base: 8, md: 8, lg: 8, xl: 0}}>
                    <TableContainer borderBottom={"2px solid grey"}>
                        <Table variant='simple' className={DedicatedStyles.dedi_plan_table}>
                        <Thead fontFamily={"heading"}>
                            <Tr borderTopRightRadius={"14px"}>
                                <Th textTransform={"capitalize"} fontSize={"md"} fontWeight={"400"} letterSpacing={"0px"}  borderTopLeftRadius={"14px"}>CPU</Th>
                                <Th textTransform={"capitalize"} fontSize={"md"} fontWeight={"400"} letterSpacing={"0px"} textAlign={"center"}>Memory</Th>
                                <Th isNumeric textTransform={"capitalize"} fontSize={"md"} fontWeight={"400"} letterSpacing={"0px"} borderTopRightRadius={"14px"} pe={"55px"}>Price</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            <Tr>
                                <Td>AMD 9254</Td>
                                <Td>384 GB</Td>
                                <Td>$1800/month</Td>
                            </Tr>
                            <Tr>
                                <Td>AMD 7543</Td>
                                <Td>1 TB</Td>
                                <Td>$2000/month</Td>
                            </Tr>
                            <Tr>
                                <Td>AMD 9354</Td>
                                <Td>512 GB</Td>
                                <Td>$2200/month</Td>
                            </Tr>
                            <Tr>
                                <Td>AMD 9354</Td>
                                <Td>768 GB</Td>
                                <Td>$2500/month</Td>
                            </Tr>
                            <Tr>
                                <Td>AMD 9254</Td>
                                <Td>1 TB</Td>
                                <Td>$2500/month</Td>
                            </Tr>
                            <Tr>
                                <Td>AMD 9354</Td>
                                <Td>1 TB</Td>
                                <Td>$2800/month</Td>
                            </Tr>
                            
                        </Tbody>
                        </Table>
                    </TableContainer>
                    </Box>
                </Box>
                <Box p={8} pt={4} pb={0} mt={0} border={"1px solid"} borderColor={"brand.white"} borderRadius={"14px"} maxW={"600px"}>
                  <Box >
                      <Box>
                          <Text as={"div"} fontFamily={"heading"} color={"brand.white"} fontSize={"4xl"} lineHeight={"3xl"} fontWeight={"600"} position={"relative"}>Dedicated gRPC Nodes</Text>
                      </Box>
                      <Box py={4}>
                          <Text as={"div"} fontSize={{ base: "14px", lg: "md" }} fontWeight={"400"} color={"brand.lighterWhite"} pt={{ base: "4px", md: "6px" }}>Shared network not enough for your requirements? Get a 50 - 200 ms boost with our dedicated nodes starting from $1800/month. Indexes can be enabled only on 1TB nodes. Transactions and gRPC plugin can be enabled on all nodes. We suggest running separate nodes for RPC and gRPC usage for optimal performance.</Text>
                          <Text as={"div"} fontSize={{ base: "14px", lg: "md" }} fontWeight={"400"} color={"brand.yellow"} pt={{ base: "4px", md: "14px" }}> Please reach out to us on <Text as={"span"} color={"brand.yellow"} _hover={{ color: "brand.red" }}>dashboard support</Text> or <Text as={"a"} href={linksConfig.DISCORD_LINK} target="_blank" color={"brand.yellow"} _hover={{ color: "brand.red" }}>discord</Text> to check for availability.</Text>
                      </Box>
                      <Box display={"flex"} justifyContent={"start"} mt={"4px"}>
                          <Button
                          as={"a"}
                          href={linksConfig.DISCORD_LINK}
                          target="_blank"
                          pt={"13px"}
                          pb={"10px"}
                          display={"block"}
                          size={"md"}
                          width={"full"}
                          height={"12"}
                          maxW={"190px"}
                          
                          bgColor={'brand.red'}
                          color={'brand.white'}
                          borderRadius={"full"}
                          textColor={"#fff"}
                          fontFamily={"heading"}
                          fontWeight={"400"}
                          _hover={{
                            bgColor: 'brand.white',
                            color: 'brand.red',
                            textDecoration: 'none',
                          }}
                          mt={{base: 5, md: 1, lg: 1}}
                          textAlign={"center"}
                        >
                          Connect with us
                        </Button>
                      </Box>
                  </Box>
                </Box>
            </Flex>
          </Box>
          <Box pt={{base: 5,md: 8}}>
            {/* <Text as={"div"} fontSize={{ base: "14px", lg: "md" }} fontWeight={"400"} color={"brand.lighterWhite"} pt={{ base: "4px", md: "6px" }}>Indexes can be enabled only on 1TB nodes. Transactions and gRPC plugin can be enabled on all nodes. We suggest running separate nodes for RPC and gRPC usage for optimal performance.</Text> */}
          </Box>
        </Box>
      </Container>
    </div>
  );
};

export default DedicatedNodes;
