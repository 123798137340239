import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Box,
  Flex,
  Text,
  IconButton,
  Stack,
  Collapse,
  Icon,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useColorModeValue,
  useBreakpointValue,
  useDisclosure,
  Container,
  Image,
  Link,
  Button,
} from "@chakra-ui/react";
import {
  HamburgerIcon,
  CloseIcon,
  ChevronDownIcon,
  // ChevronRightIcon,
} from "@chakra-ui/icons";
import { linksConfig } from "../../../utils/CommonLinks";
import HomepageDisclaimer from "../../LandingPage/HomepageDisclaimer";

const Header = () => {
  // const base_url = window.location.origin;
  const { pathname, hash, key } = useLocation();
  const { isOpen, onToggle } = useDisclosure();
  const [showDisclaimer,setShowDisclaimer] = useState(true);

  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash !== "") {
      //window.scrollTo(0, 0);
      setTimeout(() => {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (element && id === "the_products") {
          //element.scrollIntoView({block: "center"});
          const clickElem = document.getElementById("product_button");
          clickElem.click();
        } else if (element && id === "shyft-pricing") {
          const clickElem = document.getElementById("pricing_button");
          clickElem.click();
        } else if (element && id === "learn-solana-development-with-shyft") {
          const clickElem = document.getElementById("project_button");
          clickElem.click();
        } else if (id === "solana_grpc_streaming_service") {
          const clickElem = document.getElementById("grpc_button");
          clickElem.click();
        } else if (element && id === "solana-grpc-streaming-service") {
          const clickElem = document.getElementById("grpc_button");
          clickElem.click();
        } else if (id === "solana_rpc_details") {
          const clickElem = document.getElementById("rpc_button");
          clickElem.click();
        } else if (element && id === "solana-rpc-details") {
          const clickElem = document.getElementById("rpc_button");
          clickElem.click();
        } else if (id === "jup-swap-v6-api") {
          const clickElem = document.getElementById("jup_button");
          clickElem.click();
        } else if (element && id === "jup-swap-v6-api") {
          const clickElem = document.getElementById("jup_button");
          clickElem.click();
        }
      }, 0);
    }
  }, [pathname, hash, key]);
  return (
    <>
      {showDisclaimer && <HomepageDisclaimer setShowDisclaimer={setShowDisclaimer} />}
      <Link
        id="pricing_button"
        href="#shyft-pricing"
        style={{ display: "none" }}
      >
        1
      </Link>
      <Link
        id="project_button"
        href="#learn-solana-development-with-shyft"
        style={{ display: "none" }}
      >
        2
      </Link>
      <Link
        id="grpc_button"
        href="#solana-grpc-streaming-service"
        style={{ display: "none" }}
      >
        3
      </Link>
      <Link
        id="rpc_button"
        href="#solana-rpc-details"
        style={{ display: "none" }}
      >
        4
      </Link>
      <Link
        id="jup_button"
        href="#jup-swap-v6-api"
        style={{ display: "none" }}
      >
        5
      </Link>
      <Box bg={"brand.red"} pt={1} pb={2}>
      
        <Flex justifyContent={"center"}>
          <Box px={4} pt={"2px"}>
            <Text as="div" textColor={"brand.black"} pt={"2px"} fontWeight={"500"} color={"brand.white"}> Our Twitter handles are compromised, please do not interact with @codinpsycho and @Shyft_to handles. </Text>
          </Box>    
          {/* <Box px={4} pt={"2px"}>
            <Button as={"a"} href={linksConfig.DISCORD_LINK} target="_blank" fontFamily={"heading"} fontWeight={500} size={"xs"} py={2} px={6} borderRadius={"full"}>Join Discord</Button>  
          </Box>     */}
        </Flex>
      </Box>
      <Box bg={"black"}>
        <Container maxW={"7xl"}>
          <Flex
            color={"brand.white"}
            minH={"60px"}
            py={{ base: 2 }}
            px={{ base: 4 }}
            borderBottom={1}
            borderStyle={"solid"}
            borderColor={"black"}
            align={"center"}
          >
            <Flex
              flex={{ base: 1, md: "auto" }}
              ml={{ base: -2 }}
              display={{ base: "flex", md: "none" }}
            >
              <IconButton
                onClick={onToggle}
                icon={
                  isOpen ? (
                    <CloseIcon w={3} h={3} color={"brand.white"} />
                  ) : (
                    <HamburgerIcon w={5} h={5} color={"brand.white"} />
                  )
                }
                variant={"outline"}
                aria-label={"Toggle Navigation"}
                _hover={{
                  bg: "transparent",
                }}
              />
            </Flex>
            <Flex flex={{ base: 1 }} justify={{ base: "center", md: "start" }}>
              <Text
                as="a"
                href="/"
                textAlign={useBreakpointValue({ base: "center", md: "left" })}
                fontFamily={"heading"}
                color={useColorModeValue("gray.800", "white")}
              >
                <Image src="/shyft_logo.svg" alt="shyft logo" maxW={"100px"} />
              </Text>

              <Flex display={{ base: "none", md: "flex" }} ml={10}>
                <DesktopNav />
              </Flex>
            </Flex>

            <Stack
              flex={{ base: 1, md: 0 }}
              justify={"flex-end"}
              direction={"row"}
              spacing={4}
            >
              {/* <Button as={'a'} fontSize={'sm'} fontWeight={400} variant={'link'} href={'#'}>
                            Sign In
                        </Button> */}
              <Box
                as="div"
                className="postman-run-button postman_styles"
                display={{ base: "none", md: "inline-block" }}
                borderRadius={"full"}
                data-postman-action="collection/fork"
                data-postman-var-1="18419720-f8e4000d-066d-4476-9790-01620490471b"
                data-postman-collection-url="entityId=18419720-f8e4000d-066d-4476-9790-01620490471b&entityType=collection&workspaceId=8d164192-365d-4e69-8eec-54da86979a9e"
                data-postman-param="env%5BShyft%5D=W3sia2V5IjoidXJsIiwidmFsdWUiOiJodHRwczovL2FwaS5zaHlmdC50by9zb2wvdjEiLCJlbmFibGVkIjp0cnVlLCJ0eXBlIjoiZGVmYXVsdCJ9XQ=="
              ></Box>
              {/* <Link href={"postman://app/collections/import/18419720-f8e4000d-066d-4476-9790-01620490471b"}>
                            open
                        </Link> */}
              {/* <Button
                            
                            display={{ base: 'none', md: 'inline-flex' }}
                            fontSize={'sm'}
                            fontWeight={600}
                            color={'white'}
                            bg={'brand.red'}
                            borderRadius={"full"}
                            px={8}
                            _hover={{
                                bg: 'brand.white',
                                color: 'brand.red'
                            }}
                            onClick={() => router.push('/get-api-key')}
                            >
                            Get API Key
                        </Button> */}
              <Box
                as={"a"}
                href={"/get-api-key"}
                display={{ base: "inline-block", md: "inline-block" }}
                fontSize={{ base: "12px", md: "sm" }}
                fontWeight={{ base: 300, md: 500 }}
                fontFamily={"heading"}
                color={"white"}
                bg={"brand.red"}
                borderRadius={"full"}
                px={{ base: "4px", md: 8 }}
                py={{ base: "4px", md: "9px" }}
                textAlign={"center"}
                justifyContent={"center"}
                alignItems={"center"}
                width={{ base: "90px", md: "158px" }}
                transition={"all 0.2s ease"}
                _hover={{
                  bg: "brand.white",
                  color: "brand.red",
                }}
              >
                Get API Key
              </Box>
            </Stack>
          </Flex>
        </Container>
        <Collapse in={isOpen} animateOpacity>
          <MobileNav />
        </Collapse>
      </Box>
    </>
  );
};
//href={`${base_url}/#the_pricing`}
const DesktopNav = () => {
  const linkColor = useColorModeValue("brand.white", "gray.200");
  const linkHoverColor = useColorModeValue("brand.grey", "gray.800");
  const popoverContentBgColor = useColorModeValue("black", "gray.800");

  return (
    <Stack direction={"row"} spacing={4}>
      {NAV_ITEMS.map((navItem) => (
        <Box key={navItem.label}>
          <Popover trigger={"hover"} placement={"bottom-start"}>
            <PopoverTrigger>
              <Box
                as="a"
                p={2}
                href={navItem.href ?? "#"}
                target={navItem.newTab ? "_blank" : "_self"}
                cursor={navItem.cursor ?? "pointer"}
                fontSize={"sm"}
                fontWeight={500}
                color={linkColor}
                _hover={{
                  textDecoration: "none",
                  color: linkHoverColor,
                }}
              >
                {navItem.label}
              </Box>
            </PopoverTrigger>

            {navItem.children && (
              <PopoverContent
                border={0}
                boxShadow={"xl"}
                bg={popoverContentBgColor}
                p={4}
                rounded={"xl"}
                minW={"sm"}
              >
                <Stack>
                  {navItem.children.map((child) => (
                    <DesktopSubNav key={child.label} {...child} />
                  ))}
                </Stack>
              </PopoverContent>
            )}
          </Popover>
        </Box>
      ))}
    </Stack>
  );
};

const DesktopSubNav = ({ label, href, subLabel, newTab }) => {
  return (
    <Box
      as="a"
      href={href}
      target={newTab ? "_blank" : "_self"}
      role={"group"}
      display={"block"}
      p={2}
      rounded={"md"}
      _hover={{ bg: useColorModeValue("brand.red", "gray.900") }}
    >
      <Stack direction={"row"} align={"center"}>
        <Box>
          <Text
            as="div"
            transition={"all .3s ease"}
            _groupHover={{ color: "brand.white" }}
            fontWeight={500}
          >
            {label}
          </Text>
          <Text as="div" fontSize={"sm"} _groupHover={{ color: "brand.white" }}>
            {subLabel}
          </Text>
        </Box>
        {/* <Flex
                  transition={'all .3s ease'}
                  transform={'translateX(-10px)'}
                  opacity={0}
                  _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
                  justify={'flex-end'}
                  align={'center'}
                  flex={1}>
                  <Icon color={'pink.400'} w={5} h={5} as={ChevronRightIcon} />
              </Flex> */}
      </Stack>
    </Box>
  );
};

const MobileNav = () => {
  return (
    <Stack
      bg={useColorModeValue("white", "gray.800")}
      p={4}
      display={{ md: "none" }}
    >
      {NAV_ITEMS.map((navItem) => (
        <MobileNavItem key={navItem.label} {...navItem} />
      ))}
    </Stack>
  );
};

const MobileNavItem = ({ label, children, href, newTab }) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <Link
        py={2}
        as="a"
        href={href ?? "#"}
        target={newTab ? "_blank" : "_self"}
        justifyContent="space-between"
        alignItems="center"
        color={useColorModeValue("gray.600", "gray.200")}
        fontWeight={600}
        _hover={{
          textDecoration: "none",
        }}
      >
        {label}
        {children && (
          <Icon
            as={ChevronDownIcon}
            transition={"all .25s ease-in-out"}
            transform={isOpen ? "rotate(180deg)" : ""}
            w={6}
            h={6}
            display={"inline"}
          />
        )}
      </Link>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: "0!important" }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle={"solid"}
          borderColor={useColorModeValue("gray.200", "gray.700")}
          align={"start"}
        >
          {children &&
            children.map((child) => (
              <Box as="a" key={child.label} py={2} href={child.href}>
                {child.label}
              </Box>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};

const NAV_ITEMS = [
  {
    label: "Products",
    cursor: "default",
    children: [
      {
        label: "🔥Jupiter Swap API",
        subLabel: "Superfast quote and swaps for your trades.",
        href: "/solana-jupiter-swap-api",
        newTab: true,
      },
      {
        label: "🔥gRPC Network",
        subLabel: "Supercharge your Solana streaming experience",
        href: "/#solana-grpc-streaming-service",
        newTab: false,
      },
      {
        label: "SuperIndexers",
        subLabel: "GraphQL for Solana Programs",
        href: "https://docs.shyft.to/solana-indexers/graphql-apis",
        newTab: true,
      },
      {
        label: "Transaction History",
        subLabel: "Parsed Transactions for any account",
        href: "https://docs.shyft.to/start-hacking/transactions",
        newTab: true,
      },
      {
        label: "Callback APIs",
        subLabel: "Parsed Solana Webhooks",
        href: "https://docs.shyft.to/start-hacking/callbacks",
        newTab: true,
      }
    ],
  },
  {
    label: "Pricing",
    href: "/#shyft-pricing",
    newTab: false,
    // children: [
    //     // {
    //     //     label: 'Job Board',
    //     //     subLabel: 'Find your dream design job',
    //     //     href: '#',
    //     // },
    //     // {
    //     //     label: 'Freelance Projects',
    //     //     subLabel: 'An exclusive list for contract work',
    //     //     href: '#',
    //     // },
    // ],
  },
  {
    label: "Resources",
    cursor: "default",
    newTab: false,
    children: [
        {
            label: 'Shyft Docs',
            subLabel: 'All you need to know about Shyft',
            href: 'https://docs.shyft.to',
            newTab: true
        },
        {
            label: 'Shyft Blogs',
            subLabel: 'Explore examples and applications',
            href: '#',
            newTab: true
        },
    ],
  },
  // {
  //   label: "Blog",
  //   href: "https://blogs.shyft.to",
  //   newTab: true,
  // },
  // {
  //   label: "Docs",
  //   href: "https://docs.shyft.to",
  //   newTab: true,
  // },
  {
    label: "🔥 gRPCs",
    href: "/#solana-grpc-streaming-service",
    newTab: false,
  },
  {
    label: "JUP API",
    href: "/solana-jupiter-swap-api",
    newTab: true,
  }
];

export default Header;
