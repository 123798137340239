import { Box, Container, Heading, UnorderedList, ListItem, Text, Link } from '@chakra-ui/react';
import { useEffect } from "react";
import ReactGA from "react-ga4";

const Privacy = () => {
    useEffect(() => {
        document.title = 'Privacy Policy - SHYFT';
        ReactGA.send({ hitType: "pageview", page: "/privacy-policy" });
    }, []);

    return (
        <>
            
            <Box bg={'brand.black'}>
                <Container maxW={'7xl'} color={'brand.white'} py={'12'} px={8}>
                    <Heading as="h1" fontSize={{ base: '2xl', lg: '5xl' }}>
                        SHYFT - Privacy Policy
                    </Heading>

                    <Box as="div" py={{ base: '24px', lg: '32px' }} textAlign={'justify'}>
                        This Privacy Policy describes how SHYFT (https://shyft.to/) (the “Company”), with its registered
                        address Box 30, Flat No. D-23, Zonasha Vista Apartment, Haralur, Varthur Bangalore, Bangalore KA
                        560103 India, handles and process your personal data. Users of our website and services (“you,
                        your”) can provide personal data to us when visiting our website (the “Site”) and when using our
                        services. We act as the data controller of personal data obtained accordingly and of its
                        processing in accordance with this Privacy Policy. We safeguard your personal integrity. It is
                        therefore important for us to protect your personal data and ensure that our processing of your
                        personal data is correct and lawful. We ask that you read this Privacy Policy carefully and
                        inform yourself with its content, since it applies to all use of our website and services. If
                        you have any questions regarding this Privacy Policy, please contact us at the address provided
                        above or at support@shyft.to.
                    </Box>

                    <Box as="div" py={{ base: 'xl', lg: '5xl' }}>
                        <Heading as="h2" fontSize={{ base: 'xl', lg: '3xl' }}>
                            How we collect your personal data
                        </Heading>
                        <Box py={{ base: '16px', lg: '24px' }} textAlign={'justify'}>
                            We collect your personal data when you sign up for user account or in any other way provide
                            us with your personal data. We also collect your personal data based on your web activity
                            and activity in emails we send to you, including how you use the Site and services and from
                            third party sources.
                        </Box>
                    </Box>

                    <Box as="div" py={{ base: 'xl', lg: '5xl' }}>
                        <Heading as="h2" fontSize={{ base: 'xl', lg: '3xl' }}>
                            Data that we process
                        </Heading>
                        <Box py={{ base: '16px', lg: '24px' }} textAlign={'justify'}>
                            The categories of personal data we process are the following:
                            <UnorderedList py={{ base: '12px', lg: '16px' }}>
                                <ListItem>
                                    Name and contact details such as your email address, telephone number, location,
                                    professional title, and information regarding the company you represent, tax
                                    details, credit card information and the services you have ordered; Account details,
                                    including username/userID;
                                </ListItem>
                                <ListItem>
                                    Browser information, e.g. type and version of browser, any website from which you
                                    have been referred, which time zone you visit us from; pages you visit on our
                                    website; your IP-address and a rough location estimate based on your IP-address;
                                    information about your web activity or your interaction in emails we send to you;
                                    information on your use of our services.
                                </ListItem>
                                <ListItem>Account details, including username/userID;</ListItem>
                            </UnorderedList>
                            For the avoidance of doubt, we do not save the IP-addresses of requests to our blockchain
                            nodes nor do we profile or bundle calls to our blockchain nodes based on the user.
                        </Box>
                    </Box>

                    <Box as="div" py={{ base: 'xl', lg: '5xl' }}>
                        <Heading as="h2" fontSize={{ base: 'xl', lg: '3xl' }}>
                            Why and how we use your data
                        </Heading>

                        <Box py={{ base: '16px', lg: '24px' }} textAlign={'justify'}>
                            <Text fontSize={{ base: 'lg', lg: '2xl' }} pb={2}>
                                Provide our services
                            </Text>
                            We process your personal data in order to provide you with our services and to communicate
                            with you when administering your account, your purchases, processing your contact
                            information in connection with you accessing our services, invoicing, financial reporting
                            and providing support, training, professional services and general information regarding our
                            services to you. Our processing for the purpose of providing our services is based on our
                            legitimate interest to do so or on your prior consent.
                        </Box>
                        <Box py={{ base: '16px', lg: '24px' }} textAlign={'justify'}>
                            <Text fontSize={{ base: 'lg', lg: '2xl' }} pb={2}>
                                Improve our services
                            </Text>
                            We process your personal data to improve our services and make them easier and better to
                            use, including but not limited to testing and troubleshooting, fixing bugs. We will produce
                            statistics on and analyzing how you use our services and performing market research and
                            customer satisfaction surveys and getting your feedback. For these purposes, we also use
                            your personal data based on your web activity and activity associated with emails we send to
                            you, including how you use our website and services, your interests, professional title and
                            company and similarities with other users’ interests and user patterns. If you have created
                            an account for our services, we process your personal data to handle IT-security incidents
                            and matters for the purpose of generally improving the security of our IT systems, by
                            creating and keeping, for a period of 12 months from its creation, a log file on how you use
                            our website and services. Our processing for the purpose of improving our services is based
                            on our legitimate interest to do so.
                        </Box>
                        <Box py={{ base: '16px', lg: '24px' }} textAlign={'justify'}>
                            <Text fontSize={{ base: 'lg', lg: '2xl' }} pb={2}>
                                Marketing
                            </Text>
                            We also use your personal data to communicate with you about our services, to inform you
                            about our terms and conditions and to send you marketing messages. You may opt-out from
                            further marketing messages at any time by using the un-subscription link provided in every
                            marketing message. Our processing for the purpose of direct marketing is based on our
                            legitimate interest to promote our services or on your prior consent.
                        </Box>
                    </Box>

                    <Box as="div" py={{ base: 'xl', lg: '5xl' }}>
                        <Heading as="h2" fontSize={{ base: 'xl', lg: '3xl' }}>
                            How we share your personal data
                        </Heading>
                        <Box py={{ base: '16px', lg: '24px' }} textAlign={'justify'}>
                            The personal data that we collect is shared with our affiliated companies and with our third
                            party suppliers and other third parties such as to IT-infrastructure, operating and hosting
                            services, marketing research and communications, financial and payment services, customer
                            services and IT- services such as IT-support, maintenance and development, for the provision
                            of our services, including for our developing and maintenance of our services. Your personal
                            data may be transferred or disclosed to a purchaser or prospective purchaser in the event of
                            a sale, assignment, or other transfer of all or a portion of our business or assets.
                            <br />
                            When we use service providers, we will enter into a data processing agreement with the
                            service provider which requires it to ensure that your personal data is only processed in
                            accordance with our instructions and this Privacy Policy. Processing and sharing of your
                            personal data in accordance with the above is necessary for our legitimate interest to
                            provide our services or to fulfil any contract we have entered into with you or your company
                            or to comply with mandatory law.
                        </Box>
                    </Box>

                    <Box as="div" py={{ base: 'xl', lg: '5xl' }}>
                        <Heading as="h2" fontSize={{ base: 'xl', lg: '3xl' }}>
                            Transfer of your personal data outside the EU/EEA
                        </Heading>
                        <Box py={{ base: '16px', lg: '24px' }} textAlign={'justify'}>
                            When our service providers, partners or any potential purchaser of our business or assets
                            are located in or have business activities in a country outside the EU/EEA, we may transfer
                            your personal data to such countries. In the event of such transfer it will be by ensuring
                            that the country in which the recipient is located ensures an adequate level of data
                            protection, with EU 6 – step supplementary measures if necessary, or by use of standard
                            contractual clauses that the European Commission has issued ensuring suitable measures to
                            safeguard your rights and freedoms.
                        </Box>
                    </Box>

                    <Box as="div" py={{ base: 'xl', lg: '5xl' }}>
                        <Heading as="h2" fontSize={{ base: 'xl', lg: '3xl' }}>
                            For how long do we store your personal data?
                        </Heading>
                        <Box py={{ base: '16px', lg: '24px' }} textAlign={'justify'}>
                            We will only store your personal data for as long as necessary for the purposes they were
                            collected and to fulfill our legal duties to store data otherwise, but no longer than
                            permitted by applicable law.
                        </Box>
                    </Box>

                    <Box as="div" py={{ base: 'xl', lg: '5xl' }}>
                        <Heading as="h2" fontSize={{ base: 'xl', lg: '3xl' }}>
                            Security measures
                        </Heading>
                        <Box py={{ base: '16px', lg: '24px' }} textAlign={'justify'}>
                            Security measures are made by us to ensure that the personal data we keep is secure. We
                            maintain appropriate safeguards and security standards to protect your personal data against
                            unauthorized access, disclosure or misuse. We also monitor our systems to discover
                            vulnerabilities in order to protect your personal data.
                        </Box>
                    </Box>

                    <Box as="div" py={{ base: 'xl', lg: '5xl' }}>
                        <Heading as="h2" fontSize={{ base: 'xl', lg: '3xl' }}>
                            Your Rights
                        </Heading>
                        <Box py={{ base: '16px', lg: '24px' }} textAlign={'justify'}>
                            <Text fontSize={{ base: 'lg', lg: '2xl' }} pb={2}>
                                Right of access and rectification
                            </Text>
                            You have the right to information regarding which of your personal data we process and to
                            access and rectify such personal data. You may access and amend some of the information we
                            keep on you through your account settings.
                        </Box>
                        <Box py={{ base: '16px', lg: '24px' }} textAlign={'justify'}>
                            <Text fontSize={{ base: 'lg', lg: '2xl' }} pb={2}>
                                Right to erasure
                            </Text>
                            You may request that we erase your personal data without undue delay in the following
                            circumstances:
                            <UnorderedList py={{ base: '12px', lg: '16px' }}>
                                <ListItem>
                                    the personal data is no longer necessary in relation to the purposes for which they
                                    were collected or otherwise processed;
                                </ListItem>
                                <ListItem>
                                    you withdraw your consent on which the processing is based (if applicable) and there
                                    is no other legal ground for the processing;
                                </ListItem>
                                <ListItem>
                                    you object to our processing of personal data and we do not have any overriding
                                    legitimate grounds for the processing;
                                </ListItem>
                                <ListItem>the processed personal data is unlawfully processed; or</ListItem>
                                <ListItem>
                                    the processed personal data has to be erased for compliance with legal obligations.
                                </ListItem>
                            </UnorderedList>
                            We may deny your request if we are prevented from erasing your personal data by requirements
                            set out in applicable laws and regulations or if they are needed for the establishment,
                            exercise or defence of legal claims. If we cannot meet your request, we will instead
                            restrict the personal data so they cannot be used for another purpose than the purpose
                            preventing the erasure.
                        </Box>
                        <Box py={{ base: '16px', lg: '24px' }} textAlign={'justify'}>
                            <Text fontSize={{ base: 'lg', lg: '2xl' }} pb={2}>
                                Right to restriction
                            </Text>
                            You have the right to restrict the processing of your personal data in the following
                            circumstances:
                            <UnorderedList py={{ base: '12px', lg: '16px' }}>
                                <ListItem>
                                    you contest the accuracy of the personal data during a period enabling us to verify
                                    the accuracy of such data;
                                </ListItem>
                                <ListItem>
                                    the processing is unlawful and you oppose erasure of the personal data and request
                                    restriction instead;
                                </ListItem>
                                <ListItem>
                                    the personal data is no longer needed for the purposes of the processing, but are
                                    necessary for you for the establishment, exercise or defence of legal claims;
                                </ListItem>
                                <ListItem>the processed personal data is unlawfully processed; or</ListItem>
                                <ListItem>
                                    you have objected to the processing of the personal data pending the verification
                                    whether our legitimate grounds for our processing override your interests, rights
                                    and freedoms.
                                </ListItem>
                            </UnorderedList>
                            If your personal data has been restricted in accordance with this section they may, with
                            exception of storage, only be processed for the establishment, exercise or defence of legal
                            claims, or for the protection of the rights of a third party or for reasons of important
                            public interest according to INDIAN legislation or state laws.
                        </Box>
                    </Box>

                    <Box as="div" py={{ base: 'xl', lg: '5xl' }}>
                        <Heading as="h2" fontSize={{ base: 'xl', lg: '3xl' }}>
                            Right to object
                        </Heading>
                        <Box py={{ base: '16px', lg: '24px' }} textAlign={'justify'}>
                            You have the general right to object to our processing of your personal data when it is
                            based on our legitimate interest. If you object and we believe that we may still process
                            your personal data, we must demonstrate compelling legitimate grounds for the processing
                            which override your interests, rights and freedoms, or for the establishment, exercise or
                            defence of legal claims.
                        </Box>
                    </Box>

                    <Box as="div" py={{ base: 'xl', lg: '5xl' }}>
                        <Heading as="h2" fontSize={{ base: 'xl', lg: '3xl' }}>
                            Right to data portability
                        </Heading>
                        <Box py={{ base: '16px', lg: '24px' }} textAlign={'justify'}>
                            If your personal data has been provided by you and our processing of your personal data is
                            based on your consent or on the performance of a contract with you, you have the right to
                            receive the personal data concerning you in a structured, commonly used and machine-readable
                            format in order to transmit these to another service provider where it would be technically
                            feasible and can be carried out by automated means.
                        </Box>
                    </Box>

                    <Box as="div" py={{ base: 'xl', lg: '5xl' }}>
                        <Heading as="h2" fontSize={{ base: 'xl', lg: '3xl' }}>
                            Right to withdraw consent
                        </Heading>
                        <Box py={{ base: '16px', lg: '24px' }} textAlign={'justify'}>
                            When our processing of your personal data is based on your consent, you have the right to
                            withdraw your consent at any time. You may withdraw your consent through your account
                            settings by deleting your account. Please note that the lawfulness of processing based on
                            consent before its withdrawal is not affected.
                        </Box>
                    </Box>

                    <Box as="div" py={{ base: 'xl', lg: '5xl' }}>
                        <Heading as="h2" fontSize={{ base: 'xl', lg: '3xl' }}>
                            Right to file a complaint
                        </Heading>
                        <Box py={{ base: '16px', lg: '24px' }} textAlign={'justify'}>
                            You may at any time lodge a complaint with the supervisory authority if you believe that our
                            processing is performed in breach of applicable \ data protection law.
                        </Box>
                    </Box>

                    <Box as="div" py={{ base: 'xl', lg: '5xl' }}>
                        <Heading as="h2" fontSize={{ base: 'xl', lg: '3xl' }}>
                            Cancellations
                        </Heading>
                        <Box py={{ base: '16px', lg: '24px' }} textAlign={'justify'}>
                            Please refer to our <Link href={'/cancellations'}>Cancellation Policy here.</Link>
                        </Box>
                    </Box>
                    <Box as="div" py={{ base: 'xl', lg: '5xl' }}>
                        <Heading as="h2" fontSize={{ base: 'xl', lg: '3xl' }}>
                            Changes to our Privacy Policy
                        </Heading>
                        <Box py={{ base: '16px', lg: '24px' }} textAlign={'justify'}>
                            The Company reserves the right to change this policy and our Terms of Service at any time.
                            We will notify you of significant changes to our Privacy Policy by sending a notice to the
                            primary email address specified in your account or by placing a prominent notice on our
                            site. Non-material changes or clarifications will take effect immediately. You should
                            periodically check the Site and this privacy page for updates.
                        </Box>
                    </Box>
                    <Box as="div" py={{ base: 'xl', lg: '5xl' }}>
                        <Heading as="h2" fontSize={{ base: 'xl', lg: '3xl' }}>
                            Last Updated
                        </Heading>
                        <Box py={{ base: '16px', lg: '24px' }} textAlign={'justify'}>
                            This Privacy Policy was last updated on 12 oct, 2020.
                        </Box>
                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default Privacy;
